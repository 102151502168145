import { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import { getApiDomain } from './config';
import Session from "supertokens-auth-react/recipe/session";

export function useTheme() {
    const [theme, setTheme] = useState('light');

    useEffect(() => {
        async function loadTheme() {
            if (!await Session.doesSessionExist()) {
                console.log("User is not logged in. Theme loading operation skipped.");
                return;
            }

            let cookieTheme = Cookies.get('theme');
            if (!cookieTheme) {
                try {
                    const response = await axios.get(getApiDomain() + "/sessioninfo");
                    const apiTheme = response.data.metadata?.preferences?.theme || 'light';
                    Cookies.set('theme', apiTheme, { expires: 365, sameSite: 'None', secure: true });
                    setTheme(apiTheme);
                    window.location.reload();
                } catch (error) {
                    console.error("Error fetching session info:", error);
                    Cookies.set('theme', 'light', { expires: 365, sameSite: 'None', secure: true });
                    setTheme('light');
                }
            } else {
                setTheme(cookieTheme);
                try {
                    const response = await axios.get(getApiDomain() + "/sessioninfo");
                    const apiTheme = response.data.metadata?.preferences?.theme || 'light';
                    if (apiTheme !== cookieTheme) {
                        Cookies.set('theme', apiTheme, { expires: 365, sameSite: 'None', secure: true });
                        setTheme(apiTheme);
                        window.location.reload();
                    }
                } catch (error) {
                    console.error("Error fetching session info:", error);
                }
            }
        }
        loadTheme();
    }, []);

    return theme;
}