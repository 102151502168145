import React, { useState, useEffect, ReactNode } from "react";
import CookieConsent from "react-cookie-consent";

interface AppWithCookieConsentProps {
    children: ReactNode;
}

const AppWithCookieConsent: React.FC<AppWithCookieConsentProps> = ({ children }) => {
    const [showCookieConsent, setShowCookieConsent] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 500);

    useEffect(() => {
        const consent = localStorage.getItem("cookieConsent");
        if (consent === "true") {
            setShowCookieConsent(false);
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth < 844);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    const handleAccept = () => {
        localStorage.setItem("cookieConsent", "true");
        setShowCookieConsent(false);
    };

    return (
        <>
            {showCookieConsent && (
                <CookieConsent
                    location="bottom-left"
                    buttonText="Accept"
                    cookieName="cookieConsent"
                    style={{
                        background: "#2B373B",
                        width: isMobile ? "100%" : "90%",
                        maxWidth: isMobile ? "100%" : "300px",
                        padding: isMobile ? "5px" : "20px",
                        borderRadius: isMobile ? "0px" : "15px",
                        position: "fixed",
                        bottom: isMobile ? "0px" : "20px",
                        left: isMobile ? "0px" : "20px",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "space-between",
                        alignItems: "baseline",
                        zIndex: 999,
                        color: "#fff",
                        overflow: "hidden"
                    }}
                    buttonStyle={{
                        background: "#FFD42D",
                        border: "0px",
                        borderRadius: "0px",
                        boxShadow: "none",
                        color: "#4e503b",
                        cursor: "pointer",
                        flex: "0 0 auto",
                        padding: "10px 20px",
                        fontSize: "16px"
                    }}
                    contentStyle={{
                        flex: "1 1 auto"
                    }}
                    expires={150}
                    onAccept={handleAccept}
                    containerClasses={isMobile ? "mobile-cookie-consent" : ""}
                >
                    This website utilizes technologies such as cookies to enable essential site functionality and internal usage analytics. 
                    We do not use cookies for advertising. <a href="/legal/privacy" style={{ color: "#FFD42D", textDecoration: "underline" }}><b>Privacy Policy</b></a>
                </CookieConsent>
            )}
            {children}
        </>
    );
};

export default AppWithCookieConsent;
