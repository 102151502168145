import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getApiDomain } from '../config';
import Loading from "../components/Loading";

interface ProtectedRouteProps {
    children: React.ReactNode;
    requiredPermission: number;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children, requiredPermission }) => {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSessionInfo = async () => {
            try {
                let response = await axios.get(getApiDomain() + "/sessioninfo");
                const permissions = response.data.accessTokenPayload["st-perm"].v;
                const systemPermissions = permissions.filter((perm: string) => perm.startsWith("system-"));
                const systemNumbers = systemPermissions.map((perm: string) => parseInt(perm.split("-")[1]));
                const highestNumber = Math.max(...systemNumbers, 0);
                setIsAuthorized(highestNumber >= requiredPermission);
            } catch (error) {
                console.error("Error fetching session info:", error);
                setIsAuthorized(false);
            } finally {
                setLoading(false);
            }
        };

        fetchSessionInfo();
    }, [requiredPermission]);

    if (loading) {
        return <Loading />;
    }

    if (!isAuthorized) {
        return (
            <div style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center', 
                height: '100vh', 
                fontFamily: 'Saira' 
            }}>
                <div style={{ 
                    backgroundColor: 'white', 
                    borderRadius: '10px', 
                    padding: '20px', 
                    textAlign: 'center', 
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
                }}>
                    <div style={{ 
                        fontWeight: 'bold', 
                        marginBottom: '20px' 
                    }}>
                        You are not authorized to see this page
                    </div>
                    <button 
                        onClick={() => navigate('/')} 
                        style={{ 
                            padding: '10px 20px', 
                            borderRadius: '5px', 
                            backgroundColor: '#007bff', 
                            color: 'white', 
                            border: 'none', 
                            cursor: 'pointer', 
                            fontFamily: 'Saira' 
                        }}
                    >
                        Go Home
                    </button>
                </div>
            </div>
        );
    }

    return <>{children}</>;
};

export default ProtectedRoute;