import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/session";
import { GuideIcon, SignOutIcon, LightModeWhiteFill, DarkModeBlack, SignOutIconWhite, HomeIconBlack, HomeIconWhite } from "../assets/images";
import styles from "./AuthorDashboardContent.module.css";
import darkStyles from "./AuthorDarkDashboardContent.module.css";
import Cookies from "js-cookie";
import axios from "axios";
import { getApiDomain } from "../config";
import TextEditor from "../components/TextEditor";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";

export default function ContentView({ fictionId }: { fictionId: string }) {
    const navigate = useNavigate();
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedTheme = Cookies.get("theme");
        return savedTheme === "dark";
    });

    const [title, setTitle] = useState('');
    const [preChapterNote, setPreChapterNote] = useState('');
    const [chapterContent, setChapterContent] = useState('');
    const [postChapterNote, setPostChapterNote] = useState('');
    const [scheduledRelease, setScheduledRelease] = useState<number | null>(null);
    const [displayScheduledRelease, setDisplayScheduledRelease] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [wordCount, setWordCount] = useState(0);
    const dateTimePickerRef = useRef(null);

    useEffect(() => {
        Cookies.set("theme", isDarkMode ? "dark" : "light", { expires: 365, sameSite: "None", secure: true });
    }, [isDarkMode]);

    useEffect(() => {
        if (dateTimePickerRef.current) {
            flatpickr(dateTimePickerRef.current, {
                enableTime: true,
                dateFormat: "Y-m-d H:i",
                minDate: new Date(),
                onChange: (selectedDates) => {
                    const selectedDate = selectedDates[0];
                    setDisplayScheduledRelease(selectedDate.toISOString());
                    const unixTimeInMilliseconds = selectedDate.getTime();
                    console.log(unixTimeInMilliseconds);
                    setScheduledRelease(unixTimeInMilliseconds);
                }
            });
        }
    }, []);

    const currentStyles = isDarkMode ? darkStyles : styles;

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setErrorMessage('');

        if (!title || !chapterContent) {
            setErrorMessage('Please fill in all required fields.');
            return;
        }

        const formData = {
            title,
            preChapterNote,
            chapterContent,
            postChapterNote,
            scheduledRelease,
            fictionId,
            wordCount
        };

        try {
            const response = await axios.post(getApiDomain() + '/api/chapters/new', formData);
            alert('Your chapter has been submitted');
            navigate('/author-dashboard/dashboard/' + fictionId);
        } catch (error) {
            console.error('Error submitting chapter:', error);
            setErrorMessage('An error occurred while submitting your chapter. Please try again.');
        }
    };

    return (
        <>
            <div className={currentStyles.mainContainer}>
                <div className={currentStyles.innerContent}>
                    <div className={currentStyles.formContainer}>
                        <h1>Add Chapter</h1>
                        <form className={currentStyles.form} onSubmit={handleSubmit}>
                            <div className={currentStyles.formGroup}>
                                <label className={currentStyles.label}>Title</label>
                                <input 
                                    type="text" 
                                    className={currentStyles.textInput} 
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    required
                                />
                            </div>
                            <div className={currentStyles.formGroup}>
                                <label className={currentStyles.label}>Pre-chapter Author Note</label>
                                <TextEditor
                                    height={200}
                                    value={preChapterNote}
                                    contentCss={isDarkMode ? 'tinymce-5-dark' : 'tinymce-5'}
                                    skin={isDarkMode ? 'tinymce-5-dark' : 'tinymce-5'}
                                    onEditorChange={(newValue: string, _editor: any) => {
                                        setPreChapterNote(newValue);
                                    }}
                                />
                            </div>
                            <div className={currentStyles.formGroup}>
                                <label className={currentStyles.label}>Chapter Content</label>
                                <TextEditor
                                    height={400}
                                    value={chapterContent}
                                    contentCss={isDarkMode ? 'tinymce-5-dark' : 'tinymce-5'}
                                    skin={isDarkMode ? 'tinymce-5-dark' : 'tinymce-5'}
                                    onEditorChange={(newValue: string, _editor: any) => {
                                        setChapterContent(newValue);
                                        setWordCount(_editor.plugins.wordcount.getCount());
                                        console.log(wordCount);
                                    }}
                                />
                                <p>Word Count: {wordCount}</p>
                            </div>
                            <div className={currentStyles.formGroup}>
                                <label className={currentStyles.label}>Post-chapter Author Note</label>
                                <TextEditor
                                    height={200}
                                    value={postChapterNote}
                                    contentCss={isDarkMode ? 'tinymce-5-dark' : 'tinymce-5'}
                                    skin={isDarkMode ? 'tinymce-5-dark' : 'tinymce-5'}
                                    onEditorChange={(newValue: string, _editor: any) => {
                                        setPostChapterNote(newValue);
                                    }}
                                />
                            </div>
                            <div className={currentStyles.formGroup}>
                                <label className={currentStyles.label}>Scheduled Release</label>
                                <input 
                                    type="text" 
                                    className={currentStyles.textInput} 
                                    ref={dateTimePickerRef}
                                    value={displayScheduledRelease ?? ''}
                                    onChange={(e) => setScheduledRelease(parseInt(e.target.value))}
                                />
                                {scheduledRelease !== null && (
                                    <button 
                                        type="button" 
                                        className={currentStyles.clearButton} 
                                        onClick={() => {
                                            setScheduledRelease(null);
                                            setDisplayScheduledRelease('');
                                        }}
                                    >
                                        Clear
                                    </button>
                                )}
                            </div>
                            <div className={currentStyles.buttonGroup}>
                                <button type="button" className={currentStyles.previewButton}>Preview</button>
                                <button type="button" className={currentStyles.saveDraftButton}>Save Draft</button>
                                <button type="submit" className={currentStyles.publishButton}>Publish Chapter</button>
                            </div>
                        </form>
                        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    </div>
                </div>
            </div>
        </>
    );
}