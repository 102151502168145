import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/session";
import { GuideIcon, SignOutIcon, LightModeWhiteFill, DarkModeBlack, SignOutIconWhite, HomeIconBlack, HomeIconWhite } from "../assets/images";
import styles from "./AuthorDashboardContent.module.css";
import darkStyles from "./AuthorDarkDashboardContent.module.css";
import Cookies from "js-cookie";
import { updateMetadata } from "../utils/updatemetadata";
import TextEditor from "../components/TextEditor";
import axios from "axios";
import { getApiDomain } from "../config";

export default function ContentView() {
    const navigate = useNavigate();
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedTheme = Cookies.get("theme");
        return savedTheme === "dark";
    });
    const [title, setTitle] = useState('');
    const [synopsisValue, setSynopsisValue] = useState('');
    const [patreonUrl, setPatreonUrl] = useState('');
    const [chapterTitle, setChapterTitle] = useState('');
    const [chapterContentValue, setChapterContentValue] = useState('');
    const [startFromChapter1, setStartFromChapter1] = useState(false);
    const [coverImage, setCoverImage] = useState<File | null>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [displayedCoverImage, setDisplayedCoverImage] = useState<string | null>(null);

    useEffect(() => {
        Cookies.set("theme", isDarkMode ? "dark" : "light", { expires: 365, sameSite: "None", secure: true });
    }, [isDarkMode]);

    const currentStyles = isDarkMode ? darkStyles : styles;

    const handleStartFromChapter1Change = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setStartFromChapter1(event.target.value === "yes");
    };

    const handleCoverImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file && (file.type === "image/png" || file.type === "image/jpeg" || file.type === "image/jpg")) {
            const img = new Image();
            img.onload = () => {
                // Create a canvas for the displayed image (400x600)
                const displayCanvas = document.createElement('canvas');
                const displayCtx = displayCanvas.getContext('2d');
                displayCanvas.width = 400;
                displayCanvas.height = 600;

                const scaleX = 400 / img.width;
                const scaleY = 600 / img.height;
                const scale = Math.max(scaleX, scaleY);

                const newWidth = img.width * scale;
                const newHeight = img.height * scale;

                const x = (400 - newWidth) / 2;
                const y = (600 - newHeight) / 2;

                if (displayCtx) {
                    displayCtx.drawImage(img, 0, 0, img.width, img.height, x, y, newWidth, newHeight);
                    
                    // Set the displayed image
                    displayCanvas.toBlob((blob) => {
                        if (blob) {
                            const reader = new FileReader();
                            reader.onloadend = () => {
                                setDisplayedCoverImage(reader.result as string);
                            };
                            reader.readAsDataURL(blob);
                        }
                    }, file.type);
                }

                // Keep the original image for uploading
                setCoverImage(file);
            };
            img.src = URL.createObjectURL(file);
        } else {
            alert("Please select a PNG, JPG, or JPEG image.");
            event.target.value = '';
        }
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        setErrorMessage('');

        if (!coverImage || !title || !synopsisValue || !patreonUrl || (startFromChapter1 && (!chapterTitle || !chapterContentValue))) {
            setErrorMessage('Please fill in all required fields.');
            return;
        }

        const formData = new FormData();
        formData.append('title', title);
        formData.append('synopsis', synopsisValue);
        formData.append('patreonUrl', patreonUrl);
        formData.append('startFromChapter1', startFromChapter1.toString());
        if (startFromChapter1) {
            formData.append('chapterTitle', chapterTitle || '');
            formData.append('chapterContent', chapterContentValue || '');
        }

        // Append the original cover image file
        if (coverImage) {
            formData.append('coverImage', coverImage);
        }

        try {
            const response = await axios.post(getApiDomain() + '/api/fictions/new', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                maxContentLength: Infinity,
                maxBodyLength: Infinity
            });
            alert('Your fiction has been submitted');
            navigate('/author-dashboard/submissions');
        } catch (error) {
            console.error('Error submitting fiction:', error);
            setErrorMessage('An error occurred while submitting your fiction. Please try again.');
        }
    };

    return (
        <>
            <div className={currentStyles.mainContainer}>
                <div className={currentStyles.innerContent}>
                    <div className={currentStyles.createFictionContainer}>
                        <p className={currentStyles.title}>Create a Fiction Submission</p>
                        <form className={currentStyles.form} onSubmit={handleSubmit}>
                            <div className={currentStyles.formGroup}>
                                <label className={currentStyles.label}>Cover (at least 400px wide and 600px tall)</label>
                                <input 
                                    type="file" 
                                    accept=".png,.jpg,.jpeg" 
                                    className={currentStyles.fileInput} 
                                    onChange={handleCoverImageChange}
                                    required
                                />
                                {displayedCoverImage && (
                                    <img 
                                        src={displayedCoverImage} 
                                        alt="Cover preview" 
                                        style={{ maxWidth: '200px', marginTop: '10px' }} 
                                    />
                                )}
                            </div>
                            <div className={currentStyles.formGroup}>
                                <label className={currentStyles.label}>Title</label>
                                <input 
                                    type="text" 
                                    className={currentStyles.textInput} 
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    required
                                />
                            </div>
                            <div className={currentStyles.formGroup}>
                                <label className={currentStyles.label}>Synopsis</label>
                                <TextEditor
                                    height={300}
                                    value={synopsisValue}
                                    contentCss={isDarkMode ? 'tinymce-5-dark' : 'tinymce-5'}
                                    skin={isDarkMode ? 'tinymce-5-dark' : 'tinymce-5'}
                                    onEditorChange={(newValue: string, _editor: any) => {
                                        setSynopsisValue(newValue);
                                    }}
                                />
                            </div>
                            <div className={currentStyles.formGroup}>
                                <label className={currentStyles.label}>Patreon URL</label>
                                <input 
                                    type="url" 
                                    className={currentStyles.textInput} 
                                    placeholder="https://www.patreon.com/YourPatreonName"
                                    pattern="https://www\.patreon\.com/.*"
                                    title="Please enter a valid Patreon URL (e.g., https://www.patreon.com/YourPatreonName)"
                                    value={patreonUrl}
                                    onChange={(e) => setPatreonUrl(e.target.value)}
                                    required
                                />
                            </div>
                            <div className={currentStyles.formGroup}>
                                <label className={currentStyles.label}>Are you posting your fiction from chapter 1?</label>
                                <select 
                                    className={currentStyles.select} 
                                    onChange={handleStartFromChapter1Change}
                                    value={startFromChapter1 ? "yes" : "no"}
                                >
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            </div>
                            {startFromChapter1 && (
                                <div className={currentStyles.firstChapterContainer}>
                                    <span className={currentStyles.firstChapterTitle}>The First Chapter</span>
                                    <div className={currentStyles.formGroup}>
                                        <label className={currentStyles.label}>Chapter Title</label>
                                        <input 
                                            type="text" 
                                            className={`${currentStyles.textInput} ${currentStyles.chapterTitleInput}`}
                                            value={chapterTitle}
                                            onChange={(e) => setChapterTitle(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className={currentStyles.formGroup}>
                                        <label className={currentStyles.label}>Chapter Content</label>
                                        <TextEditor
                                            height={300}
                                            value={chapterContentValue}
                                            contentCss={isDarkMode ? 'tinymce-5-dark' : 'tinymce-5'}
                                            skin={isDarkMode ? 'tinymce-5-dark' : 'tinymce-5'}
                                            onEditorChange={(newValue: string, _editor: any) => {
                                                setChapterContentValue(newValue);
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                            <br />
                            <button type="submit" className={currentStyles.submitButton}>Submit your fiction</button>
                        </form>
                        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                        <p>To submit your fiction, both the fiction information and Patreon URL are required. After that, your submission will be inspected by our system and one of the staff members.</p>
                        <p>Connecting your patreon is a somewhat involved process, and for now you need to wait until one of the staff members contacts you in order to proceed.</p>
                    </div>
                </div>
            </div>
        </>
    );
}