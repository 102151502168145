// import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from './store';
import styles from "./App.module.css";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { SessionAuth } from "supertokens-auth-react/recipe/session";
import { Routes, BrowserRouter as Router, Route } from "react-router-dom";
import Roadmap from "./Roadmap";
import Profile from "./Profile";
import { PreBuiltUIList, SuperTokensConfig, ComponentWrapper } from "./config";
import PrivacyPolicy from "./PrivacyPolicy";
import TextEditor from "./TextEditor";
import Home from "./Home";
import Settings from "./Settings";
import Alert from './Alert';
import { SW_UPDATE } from './types';
import NotFoundPage from './NotFound';
import { useTheme } from './ThemeLoader';
import AuthorDashboard from "./AuthorDashboard";
import CreateFiction from "./CreateFiction";
import Submissions from "./Submissions";
import AdminDashboard from "./AdminDashboard";
import ProtectedRoute from './utils/ProtectedRoute';
import AdminSubmissions from "./AdminSubmissions";
import AuthorFictionDash from './AuthorFictionDash';
import AuthorAddChapter from './AuthorAddChapter';
import Reader from './Reader';
import FictionPage from './FictionPage';
import UnderConstruction from './UnderConstruction';
import AuthorPatreon from './AuthorPatreon';

SuperTokens.init(SuperTokensConfig);

function App() {
    // const isServiceWorkerInitialized = useSelector((state: RootState) => state.serviceWorkerInitialized);
    // const serviceWorkerRegistration = useSelector((state: RootState) => state.serviceWorkerRegistration);
    const isServiceWorkerUpdated = useSelector((state: RootState) => state.serviceWorkerUpdated);
    const theme = useTheme();

    const updateServiceWorker = () => {
        window.location.reload();
    };

    return (
        <SuperTokensWrapper>
            <ComponentWrapper>
                <div className={`${styles.App} ${theme === 'dark' ? 'darkMode' : 'lightMode'}`}>
                    <div>
                        {isServiceWorkerUpdated && (
                            <Alert
                                text="There is a new version available."
                                buttonText="Update"
                                type={SW_UPDATE}
                                onClick={updateServiceWorker}
                            />
                        )}
                    </div>
                    <Router>
                        <Routes>
                            {getSuperTokensRoutesForReactRouterDom(
                                require("react-router-dom"),
                                PreBuiltUIList,
                            )}

                            <Route path="/" element={<Roadmap />}/>
                            <Route path="/home" element={<Home />}/>
                            <Route path="/text-editor" element={<TextEditor />}/>
                            <Route path="/settings" element={<SessionAuth><Settings /></SessionAuth>}/>
                            <Route path="/support" element={<UnderConstruction />}/>
                            <Route path="/blog" element={<UnderConstruction />}/>
                            <Route path="/inbox" element={<UnderConstruction />}/>

                            <Route path="/legal/privacy" element={<PrivacyPolicy />}/>
                            
                            <Route path="/author-dashboard" element={<SessionAuth><AuthorDashboard /></SessionAuth>}/>
                            <Route path="/author-dashboard/patreon" element={<SessionAuth><AuthorPatreon /></SessionAuth>}/>
                            <Route path="/author-dashboard/submissions" element={<SessionAuth><Submissions /></SessionAuth>}/>
                            <Route path="/author-dashboard/submissions/create" element={<SessionAuth><CreateFiction /></SessionAuth>}/>

                            <Route path="/admin-dashboard" element={
                                <SessionAuth>
                                    <ProtectedRoute requiredPermission={3}>
                                        <AdminDashboard />
                                    </ProtectedRoute>
                                </SessionAuth>
                            }/>
                            <Route path="/admin-dashboard/submissions" element={
                                <SessionAuth>
                                    <ProtectedRoute requiredPermission={3}>
                                        <AdminSubmissions />
                                    </ProtectedRoute>
                                </SessionAuth>
                            }/>

                            <Route path="/author-dashboard/dashboard/:id" element={
                                <SessionAuth>
                                    <AuthorFictionDash />
                                </SessionAuth>
                            } />

                            <Route path="/author-dashboard/chapters/new/:id" element={
                                <SessionAuth>
                                    <AuthorAddChapter />
                                </SessionAuth>
                            } />    

                            
                            <Route path="/profile" element={<SessionAuth><UnderConstruction /></SessionAuth>}/>

                            <Route path="/fiction/:fictionid" element={<FictionPage />} />
                            <Route path="/fiction/:fictionid/:title" element={<FictionPage />} />

                            <Route path="/fiction/:fictionid/:title/chapter/:chapterid" element={<Reader />} />
                            <Route path="/fiction/:fictionid/:title/chapter/:chapterid/:chaptertitle" element={<Reader />} />
                            
                            <Route path="*" element={<NotFoundPage />} />
                        </Routes>
                    </Router>
                </div>
            </ComponentWrapper>
        </SuperTokensWrapper>
    );
}

export default App;