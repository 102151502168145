import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import type { Editor as TinyMCEEditor } from 'tinymce';

interface TextEditorProps {
  value: string;
  onEditorChange: (newValue: string, editor: TinyMCEEditor) => void;
  height?: number;
  contentCss?: string;
  skin?: string;
}

export default function TextEditor({ value, onEditorChange, height = 500, contentCss = 'default', skin = 'oxide' }: TextEditorProps) {
  const editorRef = useRef<TinyMCEEditor | null>(null);

  return (
    <Editor
      tinymceScriptSrc='/tinymce/tinymce.min.js'
      licenseKey='gpl'
      onInit={(_evt, editor) => {
        editorRef.current = editor;
      }}
      onEditorChange={onEditorChange}
      value={value}
      init={{
        height: height,
        menubar: false,
        plugins: [
          'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
          'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
          'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount', 'quickbars'
        ],
        toolbar: 'undo redo | save | blocks | ' +
          'bold italic forecolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'table | link image media | fullscreen |searchreplace preview wordcount code',
        toolbar_mode: 'wrap',
        table_toolbar: 'tablecellbackgroundcolor | tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
        quickbars_insert_toolbar: false,
        quickbars_selection_toolbar: 'bold italic underline | blocks | bullist numlist | removeformat | blockquote quicklink',
        contextmenu: 'inserttable | cell row column deletetable | charmap | help',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        content_css: contentCss,
        skin: skin,
        table_default_styles: {
          'border-collapse': 'collapse',
          'box-shadow': '1px 1px 1px #000000bf',
          'background': '#0000001a',
          'border': '1px solid rgba(255,255,255,.25)',
          'width': '100%',
          'background-color': '#004b7a',
          'color': '#ccc',
          'padding-left': '10px',
          'padding-right': '10px',
        }
      }}
    />
  );
}