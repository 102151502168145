import React from 'react';
import Cookies from 'js-cookie';

const MobileNotDoneChecker = () => {
    const handleYesClick = () => {
        Cookies.set("devAuthorDashView", "true", { expires: 365 });
        window.location.reload();
    };

    const handleReturnHomeClick = () => {
        window.location.href = '/';
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'white', textAlign: 'center' }}>
            <div>
                <p style={{ textAlign: 'center' }}>The Author Dashboard is currently non-functional on mobile</p>
                <p style={{ textAlign: 'center' }}>If you wish to post a chapter, please use a PC</p>
                <p style={{ textAlign: 'center' }}>Are you sure you want to continue?</p>
                <button onClick={handleReturnHomeClick} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px', display: 'block', margin: '0 auto' }}>
                    Return to Home
                </button>
                <br />
                <button onClick={handleYesClick} style={{ background: 'none', border: 'none', color: 'red', textDecoration: 'underline', cursor: 'pointer', display: 'block', margin: '0 auto' }}>
                    I understand it is not ready and probably will not work
                </button>
            </div>
        </div>
    );
};

export default MobileNotDoneChecker;