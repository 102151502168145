import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/session";
import { GuideIcon, SignOutIcon, LightModeWhiteFill, DarkModeBlack, SignOutIconWhite, HomeIconBlack, HomeIconWhite } from "../assets/images";
import styles from "./AuthorDashboardContent.module.css";
import darkStyles from "./AuthorDarkDashboardContent.module.css";
import Cookies from "js-cookie";
import axios from "axios";
import { getApiDomain } from "../config";

type Fiction = {
    id: number;
    cover: string;
    title: string;
    chapters: number;
    comments: number;
    placeholder1: number;
    placeholder2: number;
};

type Review = {
    id: number;
    reviewerName: string;
    fictionTitle: string;
    date: string;
    snippet: string;
    rating: number;
    fictionId: number;
    reviewerId: number;
    dateUnix: number;
    title: string;
    profilePicture: string;
};

type Comment = {
    id: number;
    commenterName: string;
    fictionTitle: string;
    dateUnix: number;
    snippet: string;
    profilePicture: string;
    fictionChapter: number;
};

export default function ContentView() {
    const navigate = useNavigate();
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedTheme = Cookies.get("theme");
        return savedTheme === "dark";
    });

    const [statistics, setStatistics] = useState({ fictions: 0, chapters: 0, words: 0 });
    const [fictions, setFictions] = useState<Fiction[]>([]);
    const [reviews, setReviews] = useState<Review[]>([]);
    const [comments, setComments] = useState<Comment[]>([]);

    useEffect(() => {
        Cookies.set("theme", isDarkMode ? "dark" : "light", { expires: 365, sameSite: "None", secure: true });
    }, [isDarkMode]);

    useEffect(() => {
        const fetchFictions = async () => {
            try {
                const response = await axios.get(`${getApiDomain()}/api/cashable/fictions/ownfictions/list`);
                const fetchedFictions = response.data.fictions.map((fiction: any) => ({
                    id: fiction.id,
                    cover: fiction.coverImage,
                    title: fiction.title,
                    chapters: 0,
                    comments: 0,
                    placeholder1: 0,
                    placeholder2: 0
                }));
                setFictions(fetchedFictions);
                setStatistics(prevStats => ({ ...prevStats, fictions: fetchedFictions.length }));
            } catch (error) {
                console.error('Error fetching fictions:', error);
            }
        };

        fetchFictions();
    }, []);

    const currentStyles = isDarkMode ? darkStyles : styles;

    const renderStars = (rating: number) => {
        const fullStars = Math.floor(rating);
        const halfStar = rating % 1 !== 0;
        const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

        return (
            <>
                {"★".repeat(fullStars)}
                {halfStar && "½"}
                {"☆".repeat(emptyStars)}
            </>
        );
    };

    const handleCoverImageLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const img = event.target as HTMLImageElement;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 300; // Reduced from 400
        canvas.height = 450; // Reduced from 600, maintaining aspect ratio

        const scaleX = 300 / img.naturalWidth;
        const scaleY = 450 / img.naturalHeight;
        const scale = Math.max(scaleX, scaleY);

        const newWidth = img.naturalWidth * scale;
        const newHeight = img.naturalHeight * scale;

        const x = (300 - newWidth) / 2;
        const y = (450 - newHeight) / 2;

        if (ctx) {
            ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight, x, y, newWidth, newHeight);
            img.src = canvas.toDataURL();
        }
    };

    return (
        <>
            <div className={currentStyles.mainContainer}>
                <div className={currentStyles.innerContent}>
                    {/* Statistics Bar */}
                    <div className={currentStyles.statisticsBar}>
                        <div className={currentStyles.metric}>
                            <span className={currentStyles.metricLabel}>Fictions</span>
                            <span className={currentStyles.metricValue}>{statistics.fictions}</span>
                        </div>
                        <div className={currentStyles.metric}>
                            <span className={currentStyles.metricLabel}>Total Chapters</span>
                            <span className={currentStyles.metricValue}>{statistics.chapters}</span>
                        </div>
                        <div className={currentStyles.metric}>
                            <span className={currentStyles.metricLabel}>Total Words</span>
                            <span className={currentStyles.metricValue}>{statistics.words}</span>
                        </div>
                    </div>

                    {/* Fictions Bar */}
                    <div className={currentStyles.fictionsBar}>
                        <button className={currentStyles.addNewButton} onClick={() => navigate('/author-dashboard/submissions/create')}>+ Add New</button>
                        {fictions.length > 0 ? (
                            fictions.map(fiction => (
                                <div key={fiction.id} className={currentStyles.fictionCard}>
                                    <img 
                                        src={fiction.cover} 
                                        alt="Fiction Cover" 
                                        className={currentStyles.fictionCover} 
                                        onLoad={handleCoverImageLoad}
                                    />
                                    <div className={currentStyles.fictionDetails}>
                                        <h3 className={currentStyles.fictionTitle}>
                                            {fiction.title.length > 30 ? fiction.title.slice(0, 30) + "..." : fiction.title}
                                        </h3>
                                        <div className={currentStyles.fictionButtons}>
                                            <button onClick={() => navigate(`/author-dashboard/dashboard/${fiction.id}`)}>Edit</button>
                                            <button onClick={() => navigate(`/fiction/${fiction.id}`)}>Page</button>
                                            <button onClick={() => navigate(`/author-dashboard/chapters/new/${fiction.id}`)}>Write</button>
                                        </div>
                                        <div className={currentStyles.fictionChaptersContainer}>
                                            <span className={currentStyles.fictionChaptersLabel}>Chapters:</span>
                                            <span className={currentStyles.fictionChaptersValue}>{fiction.chapters}</span>
                                        </div>
                                        <div className={currentStyles.fictionCommentsContainer}>
                                            <span className={currentStyles.fictionCommentsLabel}>Comments:</span>
                                            <span className={currentStyles.fictionCommentsValue}>{fiction.comments}</span>
                                        </div>
                                        <div className={currentStyles.fictionPlaceholder1Container}>
                                            <span className={currentStyles.fictionPlaceholder1Label}>Placeholder1:</span>
                                            <span className={currentStyles.fictionPlaceholder1Value}>{fiction.placeholder1}</span>
                                        </div>
                                        <div className={currentStyles.fictionPlaceholder2Container}>
                                            <span className={currentStyles.fictionPlaceholder2Label}>Placeholder2:</span>
                                            <span className={currentStyles.fictionPlaceholder2Value}>{fiction.placeholder2}</span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p>You do not have any fictions, sadge</p>
                        )}
                    </div>

                    {/* Bottom Section */}
                    <div className={currentStyles.bottomSection}>
                        <div className={currentStyles.recentActivityFeed}>
                            <div className={currentStyles.recentReviews}>
                                <h4 className={currentStyles.sectionTitle}>Recent Reviews</h4>
                                {reviews.length > 0 ? (
                                    reviews.map(review => (
                                        <div key={review.id} className={currentStyles.reviewItem}>
                                            <div className={currentStyles.reviewLeft}>
                                                <img src={review.profilePicture} alt="User Profile" className={currentStyles.profilePicture} />
                                                <div className={currentStyles.reviewRating}>
                                                    {renderStars(review.rating)}
                                                </div>
                                            </div>
                                            <div className={currentStyles.reviewRight}>
                                                <p className={currentStyles.reviewTitle}>{review.title}</p>
                                                <p className={currentStyles.reviewMeta}>
                                                    by {review.reviewerName} for {review.fictionTitle}
                                                    <span className={currentStyles.reviewDate}>{new Date(review.dateUnix * 1000).toLocaleDateString()} {new Date(review.dateUnix * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}</span>
                                                </p>
                                                <p className={currentStyles.reviewSnippet}>{review.snippet}</p>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p style={{ marginLeft: '10px' }}>REVIEWS SECTION IN DEVELOPMENT</p>
                                )}
                            </div>
                            <div className={currentStyles.recentComments}>
                                <h4 className={currentStyles.sectionTitle}>Recent Comments</h4>
                                {comments.length > 0 ? (
                                    comments.map(comment => (
                                        <div key={comment.id} className={currentStyles.commentItem}>
                                            <div className={currentStyles.commentLeft}>
                                                <img src={comment.profilePicture} alt="User Profile" className={currentStyles.profileCommentPicture} />
                                            </div>
                                            <div className={currentStyles.commentRight}>
                                                <p className={currentStyles.commenterName}>{comment.commenterName}</p>
                                                <div className={currentStyles.commentTitleRow}>
                                                    <p className={currentStyles.commentFictionTitle}>
                                                        {comment.fictionTitle} - {comment.fictionChapter}
                                                    </p>
                                                    <p className={currentStyles.commentDate}>{new Date(comment.dateUnix * 1000).toLocaleDateString()} {new Date(comment.dateUnix * 1000).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })}</p>
                                                </div>
                                                <p className={currentStyles.commentSnippet}>{comment.snippet}</p>
                                                <div className={currentStyles.commentActions}>
                                                    <button>Reply</button>
                                                    <button>Delete</button>
                                                    <button>Report</button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p style={{ marginLeft: '10px' }}>COMMENTS SECTION IN DEVELOPMENT</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}