import React, { useState, useEffect } from 'react';
import styles from './DashboardNavBar.module.css';
import darkStyles from './DarkDashboardNavBar.module.css';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/snowingpine_logo.png';
import Cookies from 'js-cookie';
import { updateMetadata } from '../../utils/updatemetadata';
import { LightModeWhiteFill, DarkModeWhite, PictureThick, PictureThin } from '../../assets/images';

const DashboardNavBar = () => {
    const [showContentSubmenu, setShowContentSubmenu] = useState(false);
    const [showSettingsSubmenu, setShowSettingsSubmenu] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedTheme = Cookies.get("theme");
        return savedTheme === "dark";
    });
    const [showBackgroundOverlay, setShowBackgroundOverlay] = useState(() => {
        const savedBackground = Cookies.get("background");
        return savedBackground === "true";
    });
    const navigate = useNavigate();

    useEffect(() => {
        Cookies.set("theme", isDarkMode ? "dark" : "light", { expires: 365, sameSite: "None", secure: true });
    }, [isDarkMode]);

    useEffect(() => {
        Cookies.set("background", showBackgroundOverlay ? "true" : "false", { expires: 365, sameSite: "None", secure: true });
    }, [showBackgroundOverlay]);

    const toggleTheme = async () => {
        const newTheme = isDarkMode ? "light" : "dark";
        setIsDarkMode(!isDarkMode);
        const updates = {
            preferences: {
                theme: newTheme
            }
        };
        await updateMetadata(updates);
        Cookies.set("theme", newTheme, { 
            expires: 365,
            sameSite: 'None',
            secure: true
        });
        window.location.reload();
    };

    const toggleBackgroundOverlay = () => {
        setShowBackgroundOverlay(!showBackgroundOverlay);
    };

    const currentStyles = isDarkMode ? darkStyles : styles;

    return (
        <>
            <div className={currentStyles.navbar}>
                <div className={currentStyles.logo} onClick={() => navigate('/')}>
                    <img className={currentStyles.logoImage} src={logo} alt="Snowing Pine Stories Logo" />
                    Snowing Pine Stories
                </div>
                <div className={currentStyles.scrollableContent}>
                    <button className={currentStyles.navButton} onClick={() => navigate('/admin-dashboard')}>Admin Dashboard</button>
                    <button className={currentStyles.navButton} onClick={() => navigate('/admin-dashboard/submissions')}>Fiction Submissions</button>
                    <button className={currentStyles.navButton} onClick={() => navigate('/admin-dashboard/tickets')}>Support Tickets</button>
                    
                </div>
                <div className={currentStyles.bottomButtons}>
                    <button className={currentStyles.bottomButton} onClick={toggleTheme}>
                        <img src={isDarkMode ? LightModeWhiteFill : DarkModeWhite} alt="Toggle Theme" />
                        <span>{isDarkMode ? "Light" : "Dark"}</span>
                    </button>
                    <button
                        className={`${currentStyles.bgButton} ${showBackgroundOverlay ? currentStyles.bgButtonToggled : ''}`}
                        onClick={toggleBackgroundOverlay}
                    >
                        <img src={PictureThick} alt="Background" />
                    </button>
                </div>
            </div>
            {showBackgroundOverlay && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: isDarkMode ? '#333333' : '#e0e0e0',
                        zIndex: 0
                    }}
                ></div>
            )}
        </>
    );
};

export default DashboardNavBar;