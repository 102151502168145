import React from 'react'
import styles from './badge.module.css'

type BadgeVariant = 'default' | 'secondary' | 'destructive' | 'outline'

interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: BadgeVariant
}

export function Badge({ className = '', variant = 'default', ...props }: BadgeProps) {
  const badgeClasses = [
    styles.badge,
    styles[`variant-${variant}`],
    className
  ].join(' ')

  return (
    <div className={badgeClasses} {...props} />
  )
}