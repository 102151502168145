import HomeContentView from "./HomeContentView";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import styles from "../styles/GlobalPageStyles.module.css";
import Loading from "../components/Loading";

export default function Roadmap() {
    const sessionContext = useSessionContext();

    if (sessionContext.loading === true) {
        return <Loading />;
    }

    return (
        <div className={styles.fill} id={styles.homeContainer}>
            <HomeContentView />
        </div>
    );
}