import ContentView from "./ContentView";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import styles from "../styles/GlobalDashboardStyles.module.css";
import Loading from "../components/Loading";
import DashboardNavBar from "../components/AdminNav/DashboardNavBar";
import DashboardInnerNavBar from "../components/AdminNav/DashboardInnerNavBar";
import Cookies from "js-cookie";
import MobileNotDoneChecker from "../components/MobileNotDoneChecker";

export default function AuthorDashboard() {
    const sessionContext = useSessionContext();

    if (sessionContext.loading === true) {
        return <Loading />;
    }

    const devAuthorDashView = Cookies.get("devAuthorDashView");

    if (!devAuthorDashView && window.innerWidth < 900) {
        return <MobileNotDoneChecker />;
    }

    return (
        <>
            <div className={styles.fill} id={styles.homeContainer}>
                <DashboardNavBar />
                <div className={styles.contentContainer}>
                    <DashboardInnerNavBar location="Pending Submissions" path="Home" subpath="Submissions"/>
                    <ContentView />
                </div>
            </div>  
        </>
    );
}