import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { SettingsBlackFill, SettingsWhiteFill, LightModeWhiteFill, DarkModeBlack, BlogsIcon, BlogsIconWhite, bg3 } from "../assets/images";
import styles from "../styles/GlobalContentViewStyles.module.css";
import darkStyles from "../styles/DarkGlobalContentViewStyles.module.css";
import Cookies from "js-cookie";
import { updateMetadata } from "../utils/updatemetadata";
import axios from "axios";
import { getApiDomain } from "../config";
import Session from "supertokens-auth-react/recipe/session";
import lightHomeStyles from "./LightFictionPage.module.css";
import darkHomeStyles from "./DarkFictionPage.module.css";
import MiniLoading from "../components/MiniLoading";
import FictionInfo from "./FictionInfo";
import TableOfContents from "./TableOfContents";

interface ILink {
    name: string;
    onClick: () => void;
    icon: string;
}

interface Fiction {
    id: string;
    cover: string;
    title: string;
    chapters: number;
}

export default function FictionContentView() {
    const navigate = useNavigate();
    const { fictionid } = useParams();
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [firstName, setFirstName] = useState<string | null>(null);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [fictionData, setFictionData] = useState<any>(null);
    const [fictionLoading, setFictionLoading] = useState(true);
    const [chaptersLoading, setChaptersLoading] = useState(true);
    const [chapterList, setChapterList] = useState<any>(null);
    const [earliestChapter, setEarliestChapter] = useState<any>(null);
    const [userId, setUserId] = useState<string | null>(null);
    const [coverImageUrl, setCoverImageUrl] = useState<string | null>(null);

    useEffect(() => {
        const theme = Cookies.get("theme");
        setIsDarkMode(theme === "dark");

        const fetchSessionInfo = async () => {
            if (await Session.doesSessionExist()) {
                try {
                    const response = await axios.get(getApiDomain() + "/sessioninfo");
                    const first_name = response.data.metadata?.first_name;
                    const user_id = response.data.userId;
                    if (first_name) {
                        setFirstName(first_name);
                    }
                    if (user_id) {
                        setUserId(user_id);
                    }
                } catch (error) {
                    console.error("Error fetching session info:", error);
                }
            }
        };

        const fetchFictionInfo = async () => {
            try {
                const response = await axios.get(`${getApiDomain()}/api/fictions/textinfo`, { params: { id: fictionid } });
                setFictionData(response.data);
                setError(null);
            } catch (error) {
                console.error('Error fetching fiction info:', error);
                setError('Error fetching fiction info. Please try again later.');
            } finally {
                setFictionLoading(false);
            }
        };

        const fetchChapterList = async () => {
            try {
                const response = await axios.get(`${getApiDomain()}/api/fictions/chapterlist`, { params: { id: fictionid } });
                setChapterList(response.data?.chapters);
                setError(null);
                const earliestChapter = response.data?.chapters.reduce((earliest: any, current: any) => {
                    return current.publish_time < earliest.publish_time ? current : earliest;
                }, response.data?.chapters[0]);

                setEarliestChapter(earliestChapter.id);
            } catch (error) {
                console.error('Error fetching chapter list:', error);
                setError('Error fetching chapter list. Please try again later.');
            } finally {
                setChaptersLoading(false);
            }
        };

        fetchSessionInfo();
        fetchFictionInfo();
        fetchChapterList();

        setCoverImageUrl(`${getApiDomain()}/api/fictions/imagefile/${fictionid}.jpg`);
    }, []);

    useEffect(() => {
        if (fictionData) {
            const formatTitle = (title: string) => {
                return title.toLowerCase().replace(/[^a-z0-9 ]/g, '').replace(/ /g, '-').substring(0, 100);
            };

            const formattedFictionTitle = formatTitle(fictionData.title);

            navigate(`/fiction/${fictionid}/${formattedFictionTitle}`, { replace: true });
        }
    }, [fictionData, fictionid, navigate, coverImageUrl]);

    async function toggleTheme() {
        const newTheme = isDarkMode ? "light" : "dark";
        setIsDarkMode(!isDarkMode);
        Cookies.set("theme", newTheme, { expires: 365, sameSite: "None", secure: true });
        await updateMetadata({ preferences: { theme: newTheme } });
    }

    const links: ILink[] = [
        // {
        //     name: "Settings",
        //     onClick: profileClicked,
        //     icon: isDarkMode ? SettingsWhiteFill : SettingsBlackFill,
        // },
    ];

    const currentStyles = isDarkMode ? darkStyles : styles;
    const localCurrentStyles = isDarkMode ? darkHomeStyles : lightHomeStyles;


    return (
        <>
            {fictionData && (
                <Helmet>
                    <title>{fictionData.title} | Snowing Pine Stories</title>
                    <meta name="description" content={fictionData.synopsis.split('\n').slice(0, 3).join(' ').substring(0, 200)} />
                    <meta property="og:title" content={fictionData.title} />
                    <meta property="og:description" content={fictionData.synopsis.split('\n').slice(0, 3).join(' ').substring(0, 200)} />
                    <meta property="og:image" content={coverImageUrl || ''} />
                    <meta property="og:url" content={window.location.href} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={fictionData.title} />
                    <meta name="twitter:description" content={fictionData.synopsis.split('\n').slice(0, 3).join(' ').substring(0, 200)} />
                    <meta name="twitter:image" content={coverImageUrl || ''} />
                    <meta name="author" content={fictionData.user.full_name} />
                </Helmet>
            )}
            <div className={`${localCurrentStyles.mainContainer} home-content-view`}>
                {fictionLoading ? (
                    <div style={{ margin: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <MiniLoading />
                    </div>
                ) : (
                    <>
                        <FictionInfo
                            isDarkMode={isDarkMode}
                            title={fictionData.title}
                            author={fictionData.user.full_name}
                            coverImage={coverImageUrl || ''}
                            description={fictionData.synopsis}
                            fictionId={fictionid || ''}
                            earliestChapter={earliestChapter}
                            isAuthor={fictionData.user.id === userId}
                            isFollowed={false}
                            isFavorite={false}
                            isAlreadyReading={false}
                            currentChapter={1}
                            // tags prop can be omitted if there are no tags
                        />
                    </>
                )}
                {(chaptersLoading || fictionLoading) ? (
                    <div style={{ margin: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <MiniLoading />
                    </div>
                ) : (
                    <TableOfContents isDarkMode={isDarkMode} items={chapterList} fictionId={fictionid || ''} title={fictionData.title}/>
                )}
                {/* <div className={currentStyles.bottomLinksContainer}>
                    {links.map((link) => (
                        <div className={currentStyles.button} key={link.name} role={"button"} onClick={link.onClick}>
                            <img className={currentStyles.linkIcon} src={link.icon} alt={link.name} />
                            <div>
                                {link.name}
                            </div>
                        </div>
                    ))}
                    <button className={currentStyles.button} onClick={toggleTheme}>
                        <img src={isDarkMode ? LightModeWhiteFill : DarkModeBlack} alt="Toggle Theme" />
                        <span>{isDarkMode ? "Light Mode" : "Dark Mode"}</span>
                    </button>
                </div> */}
            </div>
        </>
    );
}