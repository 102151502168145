import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/session";
import { GuideIcon, SignOutIcon, LightModeWhiteFill, DarkModeBlack, SignOutIconWhite, HomeIconBlack, HomeIconWhite } from "../assets/images";
import styles from "./AuthorDashboardContent.module.css";
import darkStyles from "./AuthorDarkDashboardContent.module.css";
import Cookies from "js-cookie";
import axios from "axios";
import { getApiDomain } from "../config";
import Loading from "../components/Loading";

interface Submission {
    id: string;
    title: string;
    created_at: string;
}

interface FullSubmission {
    title: string;
    synopsis: string;
    patreonUrl: string;
    startFromChapter1: boolean;
    chapterTitle: string;
    coverImage: string;
    chapterContent: string | null;
    created_at: string;
}

export default function ContentView() {
    const navigate = useNavigate();
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedTheme = Cookies.get("theme");
        return savedTheme === "dark";
    });
    const [submissions, setSubmissions] = useState<Submission[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [selectedSubmission, setSelectedSubmission] = useState<FullSubmission | null>(null);
    const [isLoadingSubmission, setIsLoadingSubmission] = useState(false);

    const currentStyles = isDarkMode ? darkStyles : styles;

    useEffect(() => {
        const fetchSubmissions = async () => {
            try {
                const response = await axios.get(`${getApiDomain()}/api/fictions/submissions/user/list`);
                setSubmissions(response.data.submissions);
                setIsLoading(false);
            } catch (err) {
                console.error('Error fetching submissions:', err);
                setError('An error occurred while fetching your submissions. Please try again.');
                setIsLoading(false);
            }
        };

        fetchSubmissions();
    }, []);

    const handleSubmissionClick = async (submissionId: string) => {
        setIsLoadingSubmission(true);
        setSelectedSubmission({ title: '', synopsis: '', patreonUrl: '', startFromChapter1: false, chapterTitle: '', coverImage: '', chapterContent: null, created_at: '' });
        try {
            const response = await axios.get(`${getApiDomain()}/api/fictions/submissions/user/fullsubmission`, {
                params: { id: submissionId }
            });
            setSelectedSubmission(response.data);
        } catch (err) {
            console.error('Error fetching full submission:', err);
            setError('An error occurred while fetching the submission details. Please try again.');
        } finally {
            setIsLoadingSubmission(false);
        }
    };

    const closePopup = () => {
        setSelectedSubmission(null);
    };

    const downloadChapterContent = () => {
        if (selectedSubmission && selectedSubmission.chapterContent) {
            const blob = new Blob([selectedSubmission.chapterContent], { type: 'text/html' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `${selectedSubmission.title}_chapter.html`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        }
    };

    const handleCoverImageLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const img = event.target as HTMLImageElement;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 300; // Reduced from 400
        canvas.height = 450; // Reduced from 600, maintaining aspect ratio

        const scaleX = 300 / img.naturalWidth;
        const scaleY = 450 / img.naturalHeight;
        const scale = Math.max(scaleX, scaleY);

        const newWidth = img.naturalWidth * scale;
        const newHeight = img.naturalHeight * scale;

        const x = (300 - newWidth) / 2;
        const y = (450 - newHeight) / 2;

        if (ctx) {
            ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight, x, y, newWidth, newHeight);
            img.src = canvas.toDataURL();
        }
    };

    return (
        <>
            <div className={currentStyles.mainContainer}>
                <div className={currentStyles.innerContent}>
                    <div className={currentStyles.submissionsContainer}>
                        <p className={currentStyles.title}>Pending Submissions</p>
                        {isLoading ? (
                            <p>Loading submissions...</p>
                        ) : error ? (
                            <p className={currentStyles.error}>{error}</p>
                        ) : submissions.length === 0 ? (
                            <p>No pending submissions found.</p>
                        ) : (
                            <ul className={currentStyles.submissionsList}>
                                {submissions.map((submission) => (
                                    <li 
                                        key={submission.id} 
                                        className={`${currentStyles.submissionItem} ${currentStyles.clickable}`} 
                                        onClick={() => handleSubmissionClick(submission.id)}
                                        role="button"
                                        tabIndex={0}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter' || e.key === ' ') {
                                                handleSubmissionClick(submission.id);
                                            }
                                        }}
                                    >
                                        <div className={currentStyles.submissionTitle}>{submission.title}</div>
                                        <div className={currentStyles.submissionDate}>Submitted on: {new Date(submission.created_at).toLocaleDateString()}</div>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
            {selectedSubmission && (
                <div className={currentStyles.popup}>
                    <div className={currentStyles.popupContent}>
                        <button 
                            onClick={closePopup} 
                            className={currentStyles.closeButton}
                            style={{ position: 'absolute', top: '40px', right: '20px' }}
                        >
                            X
                        </button>
                        {isLoadingSubmission ? (
                            <Loading />
                        ) : (
                            <>
                                <h2>{selectedSubmission.title}</h2>
                                <div className={currentStyles.flexContainer}>
                                    {selectedSubmission.coverImage && (
                                        <img 
                                            src={selectedSubmission.coverImage} 
                                            alt="Cover" 
                                            className={currentStyles.coverImage} 
                                            onLoad={handleCoverImageLoad}
                                        />
                                    )}
                                    <div className={currentStyles.synopsis}>
                                        <div dangerouslySetInnerHTML={{ __html: selectedSubmission.synopsis }} />
                                    </div>
                                </div>
                                <p><b>Patreon URL:</b> {selectedSubmission.patreonUrl}</p>
                                <p><b>Start from Chapter 1:</b> {selectedSubmission.startFromChapter1 ? 'Yes' : 'No'}</p>
                                <h3>{selectedSubmission.chapterTitle}</h3>
                                {selectedSubmission.chapterContent && (
                                    <button onClick={downloadChapterContent}>Download Chapter Content</button>
                                )}
                                <p>Submitted on: {new Date(selectedSubmission.created_at).toLocaleString()}</p>
                                <button onClick={closePopup}>Close</button>
                            </>
                        )}
                    </div>
                </div>
            )}
        </>
    );
}