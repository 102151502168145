import React, { useState } from 'react';
import styles from './MobileNavBar.module.css';
import stylesDark from './MobileNavBarDark.module.css';
import { HomeIconBlack, PenFilledBlack, WordlinesBlack, MailFillBlack, NotifBellFilledBlack, ProfileFillBlack, HamburgerMenuBlack, SignOutIcon, MailFillWhite, NotifBellFilledWhite, ProfileFillWhite, HamburgerMenuWhite, SignOutIconWhite } from '../assets/images/index';
import snowingPineLogo from '../assets/images/snowingpine_logo.png';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

interface MobileNavBarProps {
    handleUserClick: () => void;
    handleLoginClick: () => void;
    handleProfileClick: () => void;
    handleInboxClick: () => void;
    handleLogoutClick: () => void;
}

const MobileNavBar: React.FC<MobileNavBarProps> = ({ handleUserClick, handleLoginClick, handleProfileClick, handleInboxClick, handleLogoutClick }) => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isProfileDropdownVisible, setIsProfileDropdownVisible] = useState(false);
    const navigate = useNavigate();
    const isDarkMode = Cookies.get("theme") === "dark";
    const currentStyles = isDarkMode ? stylesDark : styles;
    
    const handleHamburgerMenuClick = () => {
        setIsDropdownVisible(!isDropdownVisible);
        setIsProfileDropdownVisible(false);
    };

    const handleProfileIconClick = () => {
        setIsProfileDropdownVisible(!isProfileDropdownVisible);
        setIsDropdownVisible(false);
    };

    return (
        <div>
            <div className={currentStyles.mobileNavBar}>
                <div className={currentStyles.topRow}>
                    <div className={currentStyles.logoContainer} onClick={() => navigate('/')} style={{ cursor: 'pointer' }}>
                        <img src={snowingPineLogo} alt="Snowing Pine Logo" className={currentStyles.logo} />
                        <span className={currentStyles.logoText}>Snowing Pine Stories</span>
                    </div>
                    <div className={currentStyles.hamburgerMenu} onClick={handleHamburgerMenuClick}>
                        <div className={currentStyles.hamburgerIcon}>
                            <img src={isDarkMode ? HamburgerMenuWhite : HamburgerMenuBlack} alt="Menu" />
                        </div>
                    </div>
                </div>
                <div className={currentStyles.bottomRow}>
                    <div className={currentStyles.icon} onClick={handleUserClick}>
                        <img src={isDarkMode ? NotifBellFilledWhite : NotifBellFilledBlack} alt="Notifications" />
                    </div>
                    <div className={currentStyles.icon} onClick={handleInboxClick}>
                        <img src={isDarkMode ? MailFillWhite : MailFillBlack} alt="Inbox" />
                    </div>
                    <div className={currentStyles.icon} onClick={handleProfileIconClick}>
                        <img src={isDarkMode ? ProfileFillWhite : ProfileFillBlack} alt="Profile" />
                    </div>
                </div>
            </div>
            <div className={`${currentStyles.dropdownMenu} ${isDropdownVisible ? currentStyles.show : ''}`}>
                <div className={currentStyles.dropdownItem}>Read</div>
                <div className={currentStyles.dropdownItem}>Support</div>
                <div className={currentStyles.searchBar}>
                    <input type="text" className={currentStyles.searchInput} placeholder="Search titles..." />
                    <button className={currentStyles.searchButton}>Search</button>
                </div>
            </div>
            {isProfileDropdownVisible && (
                <div className={currentStyles.profileDropdownMenu}>
                    <div className={currentStyles.profileDropdownItem} onClick={handleProfileClick}>
                        <img src={isDarkMode ? ProfileFillWhite : ProfileFillBlack} alt="Profile" /> My Profile
                    </div>
                    <div className={currentStyles.profileDropdownItem} onClick={handleInboxClick}>
                        <img src={isDarkMode ? MailFillWhite : MailFillBlack} alt="Inbox" /> Inbox
                    </div>
                    <div className={currentStyles.profileDropdownItem} onClick={handleLogoutClick}>
                        <img src={isDarkMode ? SignOutIconWhite : SignOutIcon} alt="Logout" /> Logout
                    </div>
                </div>
            )}
        </div>
    );
};

export default MobileNavBar;