import ThirdParty, {
    Google,
    Github,
    Apple,
    Twitter,
} from "supertokens-auth-react/recipe/thirdparty";
import EmailVerification from "supertokens-auth-react/recipe/emailverification";
import { ThirdPartyPreBuiltUI } from "supertokens-auth-react/recipe/thirdparty/prebuiltui";
import { EmailVerificationPreBuiltUI } from "supertokens-auth-react/recipe/emailverification/prebuiltui";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import { EmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/emailpassword/prebuiltui";
import Session from "supertokens-auth-react/recipe/session";

export function getApiDomain() {
    const apiPort = process.env["REACT_APP_API_PORT"] || 3001;
    const apiUrl =
        process.env["REACT_APP_API_URL"] || `http://localhost:${apiPort}`;
    return apiUrl;
}

export function getWebsiteDomain() {
    const websitePort = process.env["REACT_APP_WEBSITE_PORT"] || 3000;
    const websiteUrl =
        process.env["REACT_APP_WEBSITE_URL"] ||
        `http://localhost:${websitePort}`;
    return websiteUrl;
}

export const SuperTokensConfig = {
    appInfo: {
        appName: "Snowing Pine Stories Supertokens Demo",
        apiDomain: getApiDomain(),
        websiteDomain: getWebsiteDomain(),
    },
    // recipeList contains all the modules that you want to
    // use from SuperTokens. See the full list here: https://supertokens.com/docs/guides
    recipeList: [
        EmailPassword.init({
            onHandleEvent: (context) => {
                if (context.action === "PASSWORD_RESET_SUCCESSFUL") {
                    // todo
                } else if (context.action === "RESET_PASSWORD_EMAIL_SENT") {
                    // todo
                } else if (context.action === "SUCCESS") {
                    if (context.createdNewSession) {
                        let user = context.user;
                        if (
                            context.isNewRecipeUser &&
                            context.user.loginMethods.length === 1
                        ) {
                            // sign up success
                        } else {
                            // sign in success
                        }
                    } else {
                        // during step up or second factor auth with email password
                    }
                }
            },
        }),
        ThirdParty.init({
            onHandleEvent: (context) => {
                if (context.action === "SUCCESS") {
                    if (context.createdNewSession) {
                        let user = context.user;
                        if (
                            context.isNewRecipeUser &&
                            context.user.loginMethods.length === 1
                        ) {
                            // sign up success
                        } else {
                            // sign in success
                        }
                    } else {
                        // during linking a social account to an existing account
                    }
                }
            },
            signInAndUpFeature: {
                providers: [Google.init()],
            },
        }),
        EmailVerification.init({
            mode: "REQUIRED", // or "OPTIONAL"
        }),
        Session.init(),
    ],
};

export const recipeDetails = {
    docsLink:
        "https://supertokens.com/docs/thirdpartyemailpassword/introduction",
};

export const PreBuiltUIList = [
    ThirdPartyPreBuiltUI,
    EmailPasswordPreBuiltUI,
    EmailVerificationPreBuiltUI,
];

export const ComponentWrapper = (props: {
    children: JSX.Element;
}): JSX.Element => {
    return props.children;
};
