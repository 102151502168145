import axios from "axios";
import { getApiDomain } from "../config";
import Session from "supertokens-auth-react/recipe/session";

export async function updateMetadata(updates: object) {
    if (!await Session.doesSessionExist()) {
        console.log("User is not logged in. Metadata update operation skipped.");
        return;
    }

    try {
        const response = await axios.post(getApiDomain() + "/user/updatemetadata", { updates });
        console.log("Metadata updated successfully:\n" + JSON.stringify(response.data.updatedMetadata, null, 2));
    } catch (error) {
        console.error("Error updating metadata:", error);
        throw error;
    }
}