import { useState, useEffect } from 'react'
import { Button } from "../components/button"
import { Card } from "../components/card"
import { Badge } from "../components/badge"
import { BookOpen, Heart, Bookmark, Edit, AlertTriangle } from 'lucide-react'
import styles from './FictionInfo.module.css'
import stylesDark from './FictionInfoDark.module.css'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

interface FictionInfoProps {
  title: string
  author: string
  coverImage: string
  description: string
  tags?: string[]
  earliestChapter: number
  fictionId: string
  isFollowed: boolean
  isFavorite: boolean
  isAuthor: boolean
  isAlreadyReading: boolean
  currentChapter: number
  isDarkMode: boolean
}

export default function FictionInfo({ 
  title, 
  author, 
  coverImage, 
  description, 
  tags = [], 
  earliestChapter,
  fictionId,
  isAuthor = false,
  isFollowed = false,
  isFavorite = false,
  isAlreadyReading = false,
  currentChapter,
  isDarkMode
}: FictionInfoProps) {
  const [showFullDescription, setShowFullDescription] = useState(false)
  const navigate = useNavigate()
  
  const appliedStyles = isDarkMode ? stylesDark : styles

  return (
    <Card className={appliedStyles.card}>
      <div className={appliedStyles.content}>
        <div className={appliedStyles.imageContainer}>
          <img src={coverImage} alt={`Cover of ${title}`} className={appliedStyles.coverImage} />
        </div>
        <div className={appliedStyles.infoContainer}>
          <h2 className={appliedStyles.title}>{title}</h2>
          <p className={appliedStyles.author}>by {author}</p>
          {tags.length > 0 && (
            <div className={appliedStyles.tagContainer}>
              {tags.map((tag, index) => (
                <Badge key={index} variant="secondary" className={appliedStyles.tag}>{tag}</Badge>
              ))}
            </div>
          )}
          <div className={appliedStyles.descriptionContainer}>
            <p 
              className={showFullDescription ? '' : appliedStyles.truncate} 
              dangerouslySetInnerHTML={{ __html: description }}
            ></p>
            <button 
              onClick={() => setShowFullDescription(!showFullDescription)}
              className={appliedStyles.showMoreButton}
            >
              {showFullDescription ? 'Show Less' : 'Show More'}
            </button>
          </div>
        </div>
        <div className={appliedStyles.buttonContainer}>
            <Button 
              variant="dark-default"
              className={appliedStyles.button} 
              onClick={() => {
                const sterilizedTitle = title.toLowerCase().replace(/[^a-z0-9 ]/g, '').replace(/ /g, '-').substring(0, 100);
                const chapterToRead = isAlreadyReading ? currentChapter : earliestChapter;
                navigate(`/fiction/${fictionId}/${sterilizedTitle}/chapter/${chapterToRead}`);
              }}
            >
              <BookOpen className={appliedStyles.icon} />
              {isAlreadyReading ? 'Continue Reading' : 'Start Reading'}
            </Button>
            <Button variant="outlinewhite" className={appliedStyles.button}>
              <Bookmark className={appliedStyles.icon} />
              {isFollowed ? 'Unfollow' : 'Follow'}
            </Button>
            <Button variant="outlinewhite" className={appliedStyles.button}>
              <Heart className={appliedStyles.icon} />
              {isFavorite ? 'Unfavorite' : 'Favorite'}
            </Button>
            {isAuthor && (
              <Button 
                variant="secondary" 
                className={appliedStyles.button}
                onClick={() => {
                  window.location.href = `/author-dashboard/dashboard/${fictionId}`;
                }}
              >
                <Edit className={appliedStyles.icon} />
                Edit
              </Button>
            )}
        </div>
      </div>
      {/* <div className={appliedStyles.footer}>
        <Button variant="ghost" className={appliedStyles.footerButton}>
          Mark as NOT INTERESTED
        </Button>
        <div className={appliedStyles.footerButtonGroup}>
          <Button variant="ghost" className={appliedStyles.footerButton}>
            <Edit className={appliedStyles.icon} />
            Edit
          </Button>
          <Button variant="ghost" className={appliedStyles.footerButton}>
            <AlertTriangle className={appliedStyles.icon} />
            Report
          </Button>
        </div>
      </div> */}
    </Card>
  )
}