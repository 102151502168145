import React, { ButtonHTMLAttributes, forwardRef } from 'react'
import styles from './button.module.css'

type ButtonVariant = 'default' | 'dark-default' | 'destructive' | 'outline' | 'outlinewhite' | 'secondary' | 'ghost' | 'link'
type ButtonSize = 'default' | 'sm' | 'lg'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant
  size?: ButtonSize
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className = '', variant = 'default', size = 'default', ...props }, ref) => {
    const buttonClasses = [
      styles.button,
      styles[`variant-${variant}`],
      styles[`size-${size}`],
      className
    ].join(' ')

    return (
      <button
        className={buttonClasses}
        ref={ref}
        {...props}
      />
    )
  }
)

Button.displayName = 'Button'