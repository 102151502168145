import React, { useState, useEffect } from 'react'
import { ChevronDown, ChevronRight, ChevronUp } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import styles from './TableOfContents.module.css'
import stylesDark from './TableOfContentsDark.module.css'
import Cookies from 'js-cookie'

interface TableOfContentsItem {
  title: string
  id: number
  publish_time: number
}

interface TableOfContentsProps {
  items?: TableOfContentsItem[]
  fictionId: string
  title: string
  isDarkMode: boolean
}

const defaultItems: TableOfContentsItem[] = [
  { title: "Chapter 1: Terms of Relocation", id: 1, publish_time: 1625097600 },
  { title: "Chapter 2: Demon World", id: 2, publish_time: 1625184000 },
  { title: "Chapter 3: Tree Demons and Infernals", id: 3, publish_time: 1625270400 },
  { title: "Chapter 4: DWBS Book 1, now on Amazon!", id: 4, publish_time: 1625356800 },
  { title: "Chapter 5: Side Story: The Voice and The Bear", id: 5, publish_time: 1625443200 },
  { title: "Chapter 6: Author's Note", id: 6, publish_time: 1625529600 },
  { title: "Chapter 7: Side Story: Writing His Own Story", id: 7, publish_time: 1625616000 },
  { title: "Chapter 8: Author's Note", id: 8, publish_time: 1625702400 },
  { title: "Chapter 9: Author's Note", id: 9, publish_time: 1625788800 },
  { title: "Chapter 10: Side Story: The System, Baking, and The Earthling", id: 10, publish_time: 1625875200 },
  { title: "Chapter 11: Terms of Relocation", id: 11, publish_time: 1625961600 },
  { title: "Chapter 12: Demon World", id: 12, publish_time: 1626048000 },
  { title: "Chapter 13: Tree Demons and Infernals", id: 13, publish_time: 1626134400 },
  { title: "Chapter 14: DWBS Book 1, now on Amazon!", id: 14, publish_time: 1626220800 },
  { title: "Chapter 15: Side Story: The Voice and The Bear", id: 15, publish_time: 1626307200 },
  { title: "Chapter 16: Author's Note", id: 16, publish_time: 1626393600 },
  { title: "Chapter 17: Side Story: Writing His Own Story", id: 17, publish_time: 1626480000 },
  { title: "Chapter 18: Author's Note", id: 18, publish_time: 1626566400 },
  { title: "Chapter 19: Author's Note", id: 19, publish_time: 1626652800 },
  { title: "Chapter 20: Side Story: The System, Baking, and The Earthling", id: 20, publish_time: 1626739200 },
  { title: "Chapter 21: Terms of Relocation", id: 21, publish_time: 1626825600 },
  { title: "Chapter 22: Demon World", id: 22, publish_time: 1626912000 },
  { title: "Chapter 23: Tree Demons and Infernals", id: 23, publish_time: 1626998400 },
  { title: "Chapter 24: DWBS Book 1, now on Amazon!", id: 24, publish_time: 1627084800 },
  { title: "Chapter 25: Side Story: The Voice and The Bear", id: 25, publish_time: 1627171200 },
  { title: "Chapter 26: Author's Note", id: 26, publish_time: 1627257600 },
  { title: "Chapter 27: Side Story: Writing His Own Story", id: 27, publish_time: 1627344000 },
  { title: "Chapter 28: Author's Note", id: 28, publish_time: 1627430400 },
  { title: "Chapter 29: Author's Note", id: 29, publish_time: 1627516800 },
  { title: "Chapter 30: Side Story: The System, Baking, and The Earthling", id: 30, publish_time: 1627603200 },
]

type SortField = 'title' | 'publish_time'
type SortOrder = 'asc' | 'desc'

export default function TableOfContents({ items = defaultItems, fictionId, title, isDarkMode }: TableOfContentsProps) {
  const [entriesPerPage, setEntriesPerPage] = useState(10)
  const [searchTerm, setSearchTerm] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [sortField, setSortField] = useState<SortField>('publish_time')
  const [sortOrder, setSortOrder] = useState<SortOrder>('desc')
  const navigate = useNavigate()
  const sterilizedTitle = title.toLowerCase().replace(/[^a-z0-9 ]/g, '').replace(/ /g, '-').substring(0, 100);

  const filteredItems = items.filter(item =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const sortedItems = [...filteredItems].sort((a, b) => {
    if (sortField === 'title') {
      return sortOrder === 'asc' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title)
    } else {
      return sortOrder === 'asc' ? a.publish_time - b.publish_time : b.publish_time - a.publish_time
    }
  })

  const totalPages = Math.ceil(sortedItems.length / entriesPerPage)
  const paginatedItems = sortedItems.slice(
    (currentPage - 1) * entriesPerPage,
    currentPage * entriesPerPage
  )

  const formatDate = (timestamp: number) => {
    const diff = timestamp - Date.now()
    const isFuture = diff > 0
    const absDiff = Math.abs(diff)
    const seconds = Math.floor(absDiff / 1000) % 60
    const minutes = Math.floor(absDiff / (1000 * 60)) % 60
    const hours = Math.floor(absDiff / (1000 * 60 * 60)) % 24
    const days = Math.floor(absDiff / (1000 * 60 * 60 * 24)) % 7
    const weeks = Math.floor(absDiff / (1000 * 60 * 60 * 24 * 7)) % 4
    const months = Math.floor(absDiff / (1000 * 60 * 60 * 24 * 30))

    if (months > 0) {
      return isFuture ? `in ${months} month${months > 1 ? 's' : ''}` : `${months} month${months > 1 ? 's' : ''} ago`
    } else if (weeks > 0) {
      return isFuture ? `in ${weeks} week${weeks > 1 ? 's' : ''}` : `${weeks} week${weeks > 1 ? 's' : ''} ago`
    } else if (days > 0) {
      return isFuture ? `in ${days} day${days > 1 ? 's' : ''}` : `${days} day${days > 1 ? 's' : ''} ago`
    } else if (hours > 0) {
      return isFuture ? `in ${hours} hour${hours > 1 ? 's' : ''}` : `${hours} hour${hours > 1 ? 's' : ''} ago`
    } else if (minutes > 0) {
      return isFuture ? `in ${minutes} minute${minutes > 1 ? 's' : ''}` : `${minutes} minute${minutes > 1 ? 's' : ''} ago`
    } else {
      return isFuture ? `in ${seconds} second${seconds > 1 ? 's' : ''}` : `${seconds} second${seconds > 1 ? 's' : ''} ago`
    }
  }

  const handleSort = (field: SortField) => {
    if (field === sortField) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortField(field)
      setSortOrder('asc')
    }
  }

  const SortIcon = ({ field }: { field: SortField }) => {
    if (sortField !== field) return null
    return sortOrder === 'asc' ? <ChevronUp className="h-4 w-4 inline-block ml-1" /> : <ChevronDown className="h-4 w-4 inline-block ml-1" />
  }

  const handleChapterClick = (item: TableOfContentsItem) => {
    const sterilizedItemTitle = item.title.toLowerCase().replace(/[^a-z0-9 ]/g, '').replace(/ /g, '-').substring(0, 100);
    navigate(`/fiction/${fictionId}/${sterilizedTitle}/chapter/${item.id}/${sterilizedItemTitle}`);
  }

  const appliedStyles = isDarkMode ? stylesDark : styles

  return (
    <div className={appliedStyles.container}>
      <div className={appliedStyles.header}>
        <h2 className={appliedStyles.title}>
          <svg className={appliedStyles.titleIcon} fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
          </svg>
          TABLE OF CONTENTS
        </h2>
        <span className={appliedStyles.chapterCount}>{items.length} {items.length === 1 ? 'Chapter' : 'Chapters'}</span>
      </div>
      <div className={appliedStyles.controls}>
        <div className={appliedStyles.selectWrapper}>
          <select
            className={appliedStyles.select}
            value={entriesPerPage}
            onChange={(e) => setEntriesPerPage(Number(e.target.value))}
          >
            <option>10</option>
            <option>20</option>
            <option>50</option>
            <option>100</option>
          </select>
          <div className={appliedStyles.selectIcon}>
            <ChevronDown className="h-4 w-4" />
          </div>
        </div>
        <input
          type="text"
          placeholder="Search..."
          className={appliedStyles.searchInput}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <table className={appliedStyles.table}>
        <thead>
          <tr className={appliedStyles.tableHeader}>
            <th className={appliedStyles.tableHeaderCell} onClick={() => handleSort('title')}>
              Chapter Name
              <SortIcon field="title" />
            </th>
            <th className={`${appliedStyles.tableHeaderCell} ${appliedStyles.tableHeaderCellRight}`} onClick={() => handleSort('publish_time')}>
              Release Date
              <SortIcon field="publish_time" />
            </th>
          </tr>
        </thead>
        <tbody>
          {paginatedItems.map((item) => {
            const sterilizedItemTitle = item.title.toLowerCase().replace(/[^a-z0-9 ]/g, '').replace(/ /g, '-').substring(0, 100);
            const href = `/fiction/${fictionId}/${sterilizedTitle}/chapter/${item.id}/${sterilizedItemTitle}`;
            return (
              <tr key={item.id} className={appliedStyles.tableRow} onClick={() => handleChapterClick(item)}>
                <td className={`${appliedStyles.tableCell} ${appliedStyles.chapterTitle}`}>
                  <a href={href} style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}>{item.title}</a>
                </td>
                <td className={`${appliedStyles.tableCell} ${appliedStyles.releaseDate}`}>{formatDate(item.publish_time)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={appliedStyles.pagination}>
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            onClick={() => setCurrentPage(page)}
            className={`${appliedStyles.pageButton} ${currentPage === page ? appliedStyles.pageButtonActive : ''}`}
          >
            {page}
          </button>
        ))}
        {/* {currentPage < totalPages && (
          <button
            onClick={() => setCurrentPage(currentPage + 1)}
            className={appliedStyles.nextPageButton}
          >
            <ChevronRight className="h-4 w-4" />
          </button>
        )} */}
      </div>
    </div>
  )
}