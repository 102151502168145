import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from "../styles/GlobalContentViewStyles.module.css";
import darkStyles from "../styles/DarkGlobalContentViewStyles.module.css";
import pageStyles from "./TextEditorPage.module.css";
import TextEditor from "../components/TextEditor";
import Cookies from "js-cookie";
import { updateMetadata } from "../utils/updatemetadata";
import { LightModeWhiteFill, DarkModeBlack, HomeIconBlack, HomeIconWhite } from "../assets/images";

interface ILink {
  name: string;
  onClick: () => void;
  icon?: string;
}

export default function TextEditorPage() {
  const [value, setValue] = useState('');
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = Cookies.get("theme");
    return savedTheme === "dark";
  });
  const navigate = useNavigate();

  useEffect(() => {
    Cookies.set("theme", isDarkMode ? "dark" : "light", { 
      expires: 365,
      sameSite: 'None',
      secure: true
    });
  }, [isDarkMode]);

  const callContent = () => {
    setValue('<p>Testing...</p><p>Testing2...</p><br><p>Testing3...</p>');
  };

  const clearEditor = () => {
    setValue('');
  };

  const goHome = () => {
    navigate('/');
  };

  const toggleTheme = async () => {
    const newTheme = isDarkMode ? "light" : "dark";
    setIsDarkMode(!isDarkMode);
    const updates = {
      preferences: {
        theme: newTheme
      }
    };
    await updateMetadata(updates);
    Cookies.set("theme", newTheme, { 
      expires: 365,
      sameSite: 'None',
      secure: true
    });
    window.location.reload();
  };

  const currentStyles = isDarkMode ? darkStyles : styles;

  const links: ILink[] = [
    {
      name: "Test Import Text",
      onClick: callContent,
    },
    {
      name: "Clear Editor",
      onClick: clearEditor,
    },
    {
      name: "Home",
      onClick: goHome,
      icon: isDarkMode ? HomeIconWhite : HomeIconBlack,
    },
    {
      name: isDarkMode ? "Light Mode" : "Dark Mode",
      onClick: toggleTheme,
      icon: isDarkMode ? LightModeWhiteFill : DarkModeBlack,
    },
  ];

  return (
    <div className={currentStyles.mainContainer}>
      <h1>Text Editor Demo</h1>
      <p>This is a demo of the text editor that will eventually be available when posting chapters, writing comments, or making announcements</p>
      <div className={pageStyles.editorContainer}>
        <TextEditor
          height={600}
          value={value}
          contentCss={isDarkMode ? 'tinymce-5-dark' : 'tinymce-5'}
          skin={isDarkMode ? 'tinymce-5-dark' : 'tinymce-5'}
          onEditorChange={(newValue: any, _editor: any) => {
            setValue(newValue);
          }}
        />
      </div>
      <div className={currentStyles.bottomLinksContainer}>
        {links.map((link) => (
          <button className={currentStyles.button} key={link.name} onClick={link.onClick}>
            {link.icon && <img src={link.icon} alt={link.name} />}
            <span>{link.name}</span>
          </button>
        ))}
      </div>
    </div>
  );
}