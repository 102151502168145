import React, { useState, useEffect } from 'react';
import styles from './MobileFictionInfo.module.css';
import darkStyles from './MobileFictionInfoDark.module.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

interface MobileFictionInfoProps {
  coverImage: string;
  title: string;
  author: string;
  chapterTitle: string;
  author_id: string;
  user_id: string;
  isAuthor: boolean;
  isDarkMode: boolean;
  fictionId: string;
  patreonUrl: string;
}

const MobileFictionInfo: React.FC<MobileFictionInfoProps> = ({
  coverImage = '/bg3.png',
  title = 'Demon World Boba Shop: A Cozy Fantasy Novel',
  author = 'R.C. Joshua',
  chapterTitle = "Chapter 199: Visitor's orders",
  author_id = '1',
  user_id = '0',
  isAuthor = false,
  isDarkMode = false,
  fictionId = '1',
  patreonUrl = 'https://www.patreon.com/'
}) => {
  const appliedStyles = isDarkMode ? darkStyles : styles;
  const navigate = useNavigate();

  return (
    <div className={appliedStyles.container}>
      <div className={`${appliedStyles.flexContainer} ${appliedStyles.flexContainerMd}`}>
        <div className={appliedStyles.textContainer}>
          <a 
            href={`/fiction/${fictionId}/${title.toLowerCase().replace(/[^a-z0-9 ]/g, '').replace(/ /g, '-')}`} 
            onClick={() => navigate(`/fiction/${fictionId}/${title.toLowerCase().replace(/[^a-z0-9 ]/g, '').replace(/ /g, '-')}`)}
            style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}
          >
            <h1 className={`${appliedStyles.title} ${appliedStyles.titleMd}`}>{title}</h1>
          </a>
          <h2 className={`${appliedStyles.chapter} ${appliedStyles.chapterMd}`}>
            {chapterTitle}
          </h2>
        </div>
        {/* <div className={appliedStyles.buttonContainer}>
          <button className={`${appliedStyles.button} ${appliedStyles.buttonYellow}`}>
            Edit Chapter
          </button>
          <button className={`${appliedStyles.button} ${appliedStyles.buttonRed}`}>
            Delete
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default MobileFictionInfo;