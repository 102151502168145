import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import AppWithCookieConsent from "./AppWithCookieConsent";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";
import App from "./App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Provider } from 'react-redux';
import configureStore from './store';
import { SW_INIT, SW_UPDATE } from './types';
import InstallBanner from './InstallBanner';

const store = configureStore();
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <AppWithCookieConsent>
                <App />
                <SpeedInsights />
                <Analytics />
                <InstallBanner />
            </AppWithCookieConsent>
        </Provider>
    </React.StrictMode>
);

serviceWorkerRegistration.register({
  onSuccess: () => store.dispatch({ type: SW_INIT }),
  onUpdate: (registration) => store.dispatch({ type: SW_UPDATE, payload: registration }),
});