import { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { SettingsBlackFill, SettingsWhiteFill, LightModeWhiteFill, DarkModeBlack, BlogsIcon, BlogsIconWhite, bg3 } from "../assets/images";
import styles from "../styles/GlobalContentViewStyles.module.css";
import darkStyles from "../styles/DarkGlobalContentViewStyles.module.css";
import Cookies from "js-cookie";
import { updateMetadata } from "../utils/updatemetadata";
import axios from "axios";
import { getApiDomain } from "../config";
import Session from "supertokens-auth-react/recipe/session";
import lightHomeStyles from "./LightHome.module.css";
import darkHomeStyles from "./DarkHome.module.css";
import MiniLoading from "../components/MiniLoading";
import FictionInfo from "../components/FictionInfo";
import MobileFictionInfo from "../components/MobileFictionInfo";
import TapMenu from "../components/TapMenu";
import NotFoundPage from "../NotFound";

export default function HomeContentView() {
    const navigate = useNavigate();
    const { fictionid, chapterid } = useParams();
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [firstName, setFirstName] = useState<string | null>(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [fontSize, setFontSize] = useState(16);
    const [chapterLoading, setChapterLoading] = useState(true);
    const [chapterData, setChapterData] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);
    const [userId, setUserId] = useState<string | null>(null);

    useEffect(() => {
        const theme = Cookies.get("theme");
        setIsDarkMode(theme === "dark");

        const fetchSessionInfo = async () => {
            if (await Session.doesSessionExist()) {
                try {
                    const response = await axios.get(getApiDomain() + "/sessioninfo");
                    const first_name = response.data.metadata?.first_name;
                    const user_id = response.data.userId;
                    if (first_name) {
                        setFirstName(first_name);
                    }
                    if (user_id) {
                        setUserId(user_id);
                    }
                } catch (error) {
                    console.error("Error fetching session info:", error);
                }
            }
        };

        const fetchChapter = async () => {
            try {
                const response = await axios.get(`${getApiDomain()}/api/chapters/pull`, { params: { chapterID: chapterid, fictionID: fictionid } });
                setChapterData(response.data);
                setError(null);
            } catch (error) {
                console.error('Error fetching chapter info:', error);
                setError('Error fetching chapter info. Please try again later.');
            } finally {
                setChapterLoading(false);
            }
        };

        fetchSessionInfo();
        fetchChapter();

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [fictionid]);

    useEffect(() => {
        if (chapterData) {
            const formatTitle = (title: string) => {
                return title.toLowerCase().replace(/[^a-z0-9 ]/g, '').replace(/ /g, '-').substring(0, 100);
            };

            const formattedFictionTitle = formatTitle(chapterData.fiction_details.title);
            const formattedChapterTitle = formatTitle(chapterData.title);

            navigate(`/fiction/${fictionid}/${formattedFictionTitle}/chapter/${chapterid}/${formattedChapterTitle}`, { replace: true });
        }
    }, [chapterData, fictionid, chapterid, navigate]);

    async function changeTheme(theme: string) {
        setIsDarkMode(theme === "dark");
        Cookies.set("theme", theme, { expires: 365, sameSite: "None", secure: true });
        await updateMetadata({ preferences: { theme: theme } });
    }

    const currentStyles = isDarkMode ? darkStyles : styles;
    const localCurrentStyles = isDarkMode ? darkHomeStyles : lightHomeStyles;

    const coverImageUrl = `${getApiDomain()}/api/fictions/imagefile/${fictionid}.jpg`;

    return (
        <>
            {chapterData && (
                <Helmet>
                    <title>{chapterData.title} - {chapterData.fiction_details.title} | Snowing Pine Stories</title>
                    <meta name="description" content={chapterData.chapter_content.replace(/<[^>]+>/g, '').substring(0, 200)} />
                    <meta property="og:title" content={chapterData.fiction_details.title} />
                    <meta property="og:description" content={chapterData.chapter_content.replace(/<[^>]+>/g, '').substring(0, 200)} />
                    <meta property="og:image" content={coverImageUrl || ''} />
                    <meta property="og:url" content={window.location.href} />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={chapterData.fiction_details.title} />
                    <meta name="twitter:description" content={chapterData.chapter_content.replace(/<[^>]+>/g, '').substring(0, 200)} />
                    <meta name="twitter:image" content={coverImageUrl || ''} />
                    <meta name="author" content={chapterData.user.full_name} />
                </Helmet>
            )}
            <div className={`${localCurrentStyles.mainContainer} home-content-view`}>
                <div className={localCurrentStyles.innerContent}>
                    {chapterData && (
                        isMobile ? (
                            <MobileFictionInfo
                                fictionId={fictionid || ''}
                                patreonUrl={`https://www.patreon.com/${chapterData.fiction_details.patreon}`}
                                isDarkMode={isDarkMode}
                                coverImage={coverImageUrl}
                                title={chapterData.fiction_details.title}
                                author={chapterData.user.full_name}
                                chapterTitle={chapterData.title}
                                author_id={chapterData.fiction_details.creator_id}
                                user_id={userId || '0'}
                                isAuthor={userId === chapterData.fiction_details.creator_id}
                            />
                        ) : (
                            <FictionInfo
                                fictionId={fictionid || ''}
                                patreonUrl={`https://www.patreon.com/${chapterData.fiction_details.patreon}`}
                                isDarkMode={isDarkMode}
                                coverImage={coverImageUrl}
                                title={chapterData.fiction_details.title}
                                author={chapterData.user.full_name}
                                chapterTitle={chapterData.title}
                                author_id={chapterData.fiction_details.creator_id}
                                user_id={userId || '0'}
                                isAuthor={userId === chapterData.fiction_details.creator_id}
                            />
                        )
                    )}
                    {chapterLoading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <MiniLoading />
                        </div>
                    ) : (
                        <>
                            {chapterData ? (
                                <div className={localCurrentStyles.chapterContent}>
                                    <div 
                                        dangerouslySetInnerHTML={{ __html: chapterData.chapter_content }} 
                                        className={localCurrentStyles.chapterContentTable}
                                    />
                                </div>
                            ) : (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                                    <h1>Error 404: Chapter Not Found</h1>
                                </div>
                            )}
                        </>
                    )}
                </div>
                
                {drawerOpen && <TapMenu setDrawerOpen={setDrawerOpen} changeTheme={changeTheme} fontSize={fontSize} setFontSize={setFontSize} fictionID={fictionid || 'null'}/>}
            </div>
        </>
    );
}