import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styles from './Alert.module.css';

interface AlertProps {
  text: string;
  buttonText?: string;
  type: string;
  onClick?: () => void;
}

const Alert: React.FC<AlertProps> = ({ text, buttonText, type, onClick }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!onClick) {
      const timer = setTimeout(() => {
        dispatch({ type });
      }, 6000);
      return () => clearTimeout(timer);
    }
  }, [dispatch, onClick, type]);

  return (
    <div className={styles.alert}>
      <div className={styles.alertContent}>
        <div className={styles.alertHeader}>
          <h2 className={styles.alertTitle}>Please Reload The Page</h2>
        </div>
        <p className={styles.alertText}>{text}</p>
        {buttonText && (
          <div className={styles.buttonContainer}>
            <button onClick={onClick}>{buttonText}</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Alert;