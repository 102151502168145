import React, { useState, useEffect } from 'react';
import { Star } from 'lucide-react';
import styles from './FictionInfo.module.css';
import darkStyles from './FictionInfoDark.module.css';
import Cookies from 'js-cookie';

interface FictionInfoProps {
  coverImage: string;
  title: string;
  author: string;
  chapterTitle: string;
  author_id: string;
  user_id: string;
  isAuthor: boolean;
  isDarkMode: boolean;
  fictionId: string;
  patreonUrl: string;
}

const FictionInfo: React.FC<FictionInfoProps> = ({
  coverImage = '/bg3.png',
  title = 'Demon World Boba Shop: A Cozy Fantasy Novel',
  author = 'R.C. Joshuaz',
  chapterTitle = "Chapter 199: Visitor's orders",
  author_id = '1',
  user_id = '0',
  isAuthor = false,
  isDarkMode = false,
  fictionId = '1',
  patreonUrl = 'https://www.patreon.com/snowingpine'
}) => {
  const appliedStyles = isDarkMode ? darkStyles : styles;

  return (
    <div className={appliedStyles.container}>
      <div className={`${appliedStyles.flexContainer} ${appliedStyles.flexContainerMd}`}>
        <img
          src={coverImage}
          alt={`Cover of ${title}`}
          className={appliedStyles.coverImage}
        />
        <div className={appliedStyles.textContainer}>
          <h1 className={`${appliedStyles.title} ${appliedStyles.titleMd}`}>{title}</h1>
          <p className={appliedStyles.author}>by {author}</p>
          <h2 className={`${appliedStyles.chapter} ${appliedStyles.chapterMd}`}>
            {chapterTitle}
          </h2>
        </div>
        <div className={appliedStyles.buttonContainer}>
          <a href={`/fiction/${fictionId}/${title.toLowerCase().replace(/[^a-z0-9 ]/g, '').replace(/ /g, '-')}`}>
            <button className={`${appliedStyles.button} ${appliedStyles.buttonBlue}`}>
              Fiction Page
            </button>
          </a>
          {/* <button className={`${appliedStyles.button} ${appliedStyles.buttonGreen}`}>
            Donate
          </button> */}
          {!isAuthor ? (
            <>
              <button className={`${appliedStyles.button} ${appliedStyles.buttonYellow}`}>
                Edit Chapter
              </button>
              <button className={`${appliedStyles.button} ${appliedStyles.buttonRed}`}>
                Delete
              </button>
            </>
          ) : (
            <a href={patreonUrl} target="_blank" rel="noopener noreferrer">
              <button className={`${appliedStyles.button} ${appliedStyles.buttonYellow}`}>
                Patreon Page
              </button>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default FictionInfo;