import React, { useState, useEffect } from 'react';
import PWAPrompt from 'react-ios-pwa-prompt';
import Cookies from 'js-cookie';

const InstallBanner: React.FC = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const [declineCount, setDeclineCount] = useState(0);

  useEffect(() => {
    const isIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
    setIsIOS(isIOSDevice);

    const handleBeforeInstallPrompt = (e: Event) => {
      console.log("beforeinstallprompt", e);
      e.preventDefault();
      setDeferredPrompt(e);

      const visitCount = parseInt(Cookies.get('visitCount') || '0', 10) + 1;
      Cookies.set('visitCount', visitCount.toString());
      console.log("visitCount", visitCount);

      const lastDismissed = Cookies.get('installBannerDismissed');
      const storedDeclineCount = Cookies.get('installBannerDeclineCount');
      
      if (lastDismissed && storedDeclineCount) {
        const timeSinceDismiss = Date.now() - parseInt(lastDismissed);
        const parsedDeclineCount = parseInt(storedDeclineCount);
        
        if (parsedDeclineCount >= 3 && timeSinceDismiss < 50 * 60 * 60 * 1000) { // 50 hours in milliseconds
          setIsVisible(false);
        } else if (parsedDeclineCount < 3 && timeSinceDismiss < 5 * 60 * 60 * 1000) { // 5 hours in milliseconds
          setIsVisible(false);
        } else {
          setDeclineCount(parsedDeclineCount);
          if (visitCount >= 2) {
            setIsVisible(true);
          }
        }
      } else {
        if (visitCount >= 2) {
          setIsVisible(true);
        }
      }
    };

    if (!isIOSDevice) {
      window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

      return () => {
        window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      };
    } else {
      handleBeforeInstallPrompt(new Event('beforeinstallprompt'));
    }
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      console.log("deferredPrompt", deferredPrompt);
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      setDeferredPrompt(null);
      setIsVisible(false);
      if (outcome === 'accepted') {
        console.log('User accepted the install prompt.');
      } else if (outcome === 'dismissed') {
        console.log('User dismissed the install prompt');
      }
    }
  };

  const handleDismiss = () => {
    setIsVisible(false);
    const newDeclineCount = declineCount + 1;
    setDeclineCount(newDeclineCount);
    Cookies.set('installBannerDismissed', Date.now().toString());
    Cookies.set('installBannerDeclineCount', newDeclineCount.toString());
  };

  const handleIOSClose = () => {
    const newDeclineCount = declineCount + 1;
    setDeclineCount(newDeclineCount);
    Cookies.set('installBannerDismissed', Date.now().toString());
    Cookies.set('installBannerDeclineCount', newDeclineCount.toString());
  };

  if (isIOS) {
    return (
      <PWAPrompt 
        delay={1000}
        onClose={handleIOSClose}
        copyTitle="Add to Home Screen"
        copySubtitle={String(window.location.href)}
        copyDescription="Snowing Pine Stories has app functionality. Add it to your home screen to use it in fullscreen and offline."
        copyShareStep="Press the 'Share' button on the menu bar below"
        appIconPath={`https://s2.googleusercontent.com/s2/favicons?domain=${window.location.origin}`}
        isShown={isVisible}
      />
    );
  }

  if (!isVisible) {
    return null;
  }

  return (
    <div style={styles.banner}>
      <div style={styles.content}>
        <div style={styles.header}>
          <img src="/snowingpine_logo.png" alt="Snowing Pine Logo" style={styles.logo} />
          <p style={styles.title}>Install Snowing Pine Stories</p>
          <button onClick={handleInstallClick} style={styles.installButton}>Install</button>
        </div>
        <p style={styles.text}>Install our app for a better reading experience</p>
        <p style={styles.subText}>
          Read stories in fullscreen without the usual browser clutter. Get notifications as soon as a new chapter is released.
        </p>
        <div style={styles.buttonContainer}>
          <button onClick={handleDismiss} style={styles.dismissButton}>No, thanks</button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  banner: {
    position: 'fixed' as 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    height: 'auto',
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    padding: '20px',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.3)',
    animation: 'slideUp 0.5s ease-in-out',
    textAlign: 'center' as 'center',
  },
  content: {
    textAlign: 'center' as 'center',
    maxWidth: '600px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    justifyContent: 'center',
    gap: '20px',
  },
  logo: {
    width: '40px',
    height: '40px',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  text: {
    fontSize: '20px',
    marginBottom: '10px',
  },
  subText: {
    fontSize: '14px',
    marginBottom: '20px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
  },
  installButton: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    backgroundColor: '#FFD42D',
    color: '#000',
    border: 'none',
    borderRadius: '20px',
  },
  dismissButton: {
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    color: '#fff',
    border: '1px solid #fff',
    borderRadius: '20px',
  },
  '@keyframes slideUp': {
    from: {
      transform: 'translateY(100%)',
    },
    to: {
      transform: 'translateY(0)',
    },
  },
};

export default InstallBanner;
