import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/session";
import { GuideIcon, SignOutIcon, LightModeWhiteFill, DarkModeBlack, SignOutIconWhite, HomeIconBlack, HomeIconWhite } from "../assets/images";
import styles from "./AuthorDashboardContent.module.css";
import darkStyles from "./AuthorDarkDashboardContent.module.css";
import Cookies from "js-cookie";
import axios from "axios";
import { getApiDomain } from "../config";

type Fiction = {
    id: number;
    coverImage: string;
    title: string;
    synopsis: string;
    patreon: string;
    created_at: string;
    creator_id: string;
};

type ContentViewProps = {
    fictionId: string;
};

export default function ContentView({ fictionId }: ContentViewProps) {
    const navigate = useNavigate();
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedTheme = Cookies.get("theme");
        return savedTheme === "dark";
    });

    const [fiction, setFiction] = useState<Fiction | null>(null);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        Cookies.set("theme", isDarkMode ? "dark" : "light", { expires: 365, sameSite: "None", secure: true });
    }, [isDarkMode]);

    useEffect(() => {
        const fetchFiction = async () => {
            try {
                const response = await axios.get(`${getApiDomain()}/api/fictions/info`, { params: { id: fictionId } });
                const fetchedFiction = response.data;
                setFiction(fetchedFiction);
                setError(null);
            } catch (error) {
                console.error('Error fetching fiction info:', error);
                setError('Error fetching fiction info. Please try again later.');
            }
        };

        fetchFiction();
    }, [fictionId]);

    const currentStyles = isDarkMode ? darkStyles : styles;
    const handleCoverImageLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        const img = event.target as HTMLImageElement;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 300; // Reduced from 400
        canvas.height = 450; // Reduced from 600, maintaining aspect ratio

        const scaleX = 300 / img.naturalWidth;
        const scaleY = 450 / img.naturalHeight;
        const scale = Math.max(scaleX, scaleY);

        const newWidth = img.naturalWidth * scale;
        const newHeight = img.naturalHeight * scale;

        const x = (300 - newWidth) / 2;
        const y = (450 - newHeight) / 2;

        if (ctx) {
            ctx.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight, x, y, newWidth, newHeight);
            img.src = canvas.toDataURL();
        }
    };

    return (
        <>
            <div className={currentStyles.mainContainer}>
                <div className={currentStyles.innerContent}>
                    {error ? (
                        <p>{error}</p>
                    ) : fiction ? (
                        <div className={currentStyles.fictionCard}>
                            <div className={currentStyles.fictionHeader}>
                                <div className={currentStyles.fictionRow}>
                                    <img 
                                        src={fiction.coverImage} 
                                        alt="Fiction Cover" 
                                        className={currentStyles.fictionCover} 
                                        onLoad={handleCoverImageLoad}
                                    />
                                    <div className={currentStyles.fictionButtons}>
                                        <button onClick={() => navigate(`/author-dashboard/chapters/new/${fictionId}`)}>Add Chapter</button>
                                        <button onClick={() => navigate(`/author-dashboard/settings/edit/${fictionId}`)}>Edit Fiction</button>
                                    </div>
                                </div>
                                <div className={currentStyles.fictionInfo}>
                                    <h1 className={currentStyles.fictionTitle}>{fiction.title}</h1>
                                    <div className={currentStyles.fictionSynopsis} dangerouslySetInnerHTML={{ __html: fiction.synopsis }} />
                                    <p className={currentStyles.fictionPatreon}>Patreon: <a href={`https://www.patreon.com/${fiction.patreon}`}>{fiction.patreon}</a></p>
                                    <p className={currentStyles.fictionCreatedAt}>Created at: {new Date(fiction.created_at).toLocaleDateString()}</p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <p style={{ 
                            backgroundColor: isDarkMode ? '#2e2e2e' : '#ffffff', 
                            color: isDarkMode ? '#ffffff' : '#000000',
                            borderRadius: '10px', 
                            padding: '10px' 
                        }}>
                            Loading fiction information...
                        </p>
                    )}
                </div>
            </div>
        </>
    );
}