import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signOut } from "supertokens-auth-react/recipe/session";
import { GuideIcon, SignOutIcon, LightModeWhiteFill, DarkModeBlack, SignOutIconWhite, HomeIconBlack, HomeIconWhite } from "../assets/images";
import styles from "./AuthorDashboardContent.module.css";
import darkStyles from "./AuthorDarkDashboardContent.module.css";
import Cookies from "js-cookie";
import { updateMetadata } from "../utils/updatemetadata";

type Fiction = {
    id: number;
    cover: string;
    title: string;
    chapters: number;
    comments: number;
    placeholder1: number;
    placeholder2: number;
};

type Review = {
    id: number;
    reviewerName: string;
    fictionTitle: string;
    date: string;
    snippet: string;
    rating: number;
    fictionId: number;
    reviewerId: number;
    dateUnix: number;
    title: string;
    profilePicture: string;
};

type Comment = {
    id: number;
    commenterName: string;
    fictionTitle: string;
    dateUnix: number;
    snippet: string;
    profilePicture: string;
    fictionChapter: number;
};

export default function ContentView() {
    const navigate = useNavigate();
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedTheme = Cookies.get("theme");
        return savedTheme === "dark";
    });

    const [statistics, setStatistics] = useState({ fictions: 0, chapters: 0, words: 0 });
    const [fictions, setFictions] = useState<Fiction[]>([]);
    const [reviews, setReviews] = useState<Review[]>([]);
    const [comments, setComments] = useState<Comment[]>([]);

    useEffect(() => {
        Cookies.set("theme", isDarkMode ? "dark" : "light", { expires: 365, sameSite: "None", secure: true });
    }, [isDarkMode]);

    useEffect(() => {
        // Dummy API calls
        // setStatistics({ fictions: 10, chapters: 50, words: 120000 });
        // setFictions([]);
        // setReviews([]);
        // setComments([]);
    }, []);

    const currentStyles = isDarkMode ? darkStyles : styles;

    const renderStars = (rating: number) => {
        const fullStars = Math.floor(rating);
        const halfStar = rating % 1 !== 0;
        const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

        return (
            <>
                {"★".repeat(fullStars)}
                {halfStar && "½"}
                {"☆".repeat(emptyStars)}
            </>
        );
    };

    return (
        <>
            <div className={currentStyles.mainContainer}>
                <div className={currentStyles.innerContent}>
                    {/* Statistics Bar */}
                    <div className={currentStyles.statisticsBar}>
                        <div className={currentStyles.metric}>
                            <span className={currentStyles.metricLabel}>Total Site Fictions</span>
                            <span className={currentStyles.metricValue}>{statistics.fictions}</span>
                        </div>
                        <div className={currentStyles.metric}>
                            <span className={currentStyles.metricLabel}>Total Site Chapters</span>
                            <span className={currentStyles.metricValue}>{statistics.chapters}</span>
                        </div>
                        <div className={currentStyles.metric}>
                            <span className={currentStyles.metricLabel}>Total Site Words</span>
                            <span className={currentStyles.metricValue}>{statistics.words}</span>
                        </div>
                    </div>

                    {/* Fictions Bar */}
                    <div className={currentStyles.fictionsBar}>
                        <div className={currentStyles.underDevelopment}>
                            <h2>Under Development</h2>
                            <p>Only Submissions page works for now. I am probably the only one that will ever see this message lol.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}