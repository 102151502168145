import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SettingsBlackFill, SettingsWhiteFill, LightModeWhiteFill, DarkModeBlack, BlogsIcon, BlogsIconWhite } from "../assets/images";
import styles from "../styles/GlobalContentViewStyles.module.css";
import darkStyles from "../styles/DarkGlobalContentViewStyles.module.css";
import Cookies from "js-cookie";
import { updateMetadata } from "../utils/updatemetadata";
import axios from "axios";
import { getApiDomain } from "../config";
import Session from "supertokens-auth-react/recipe/session";

interface ILink {
    name: string;
    onClick: () => void;
    icon: string;
}

export default function HomeContentView() {
    const navigate = useNavigate();
    const [isDarkMode, setIsDarkMode] = useState(false);
    const [firstName, setFirstName] = useState<string | null>(null);

    useEffect(() => {
        const theme = Cookies.get("theme");
        setIsDarkMode(theme === "dark");

        const fetchSessionInfo = async () => {
            if (await Session.doesSessionExist()) {
                try {
                    const response = await axios.get(getApiDomain() + "/sessioninfo");
                    const first_name = response.data.metadata?.first_name;
                    if (first_name) {
                        setFirstName(first_name);
                    }
                } catch (error) {
                    console.error("Error fetching session info:", error);
                }
            }
        };

        fetchSessionInfo();
    }, []);

    async function profileClicked() {
        navigate("/settings");
    }

    function openLink(url: string) {
        window.open(url, "_blank");
    }

    async function toggleTheme() {
        const newTheme = isDarkMode ? "light" : "dark";
        setIsDarkMode(!isDarkMode);
        Cookies.set("theme", newTheme, { expires: 365, sameSite: "None", secure: true });
        await updateMetadata({ preferences: { theme: newTheme } });
    }

    async function onTextEditorClicked() {
        navigate("/text-editor");
    }

    const links: ILink[] = [
        {
            name: "Settings",
            onClick: profileClicked,
            icon: isDarkMode ? SettingsWhiteFill : SettingsBlackFill,
        },
    ];

    const currentStyles = isDarkMode ? darkStyles : styles;
    const [loadingText, setLoadingText] = useState("Loading");

    useEffect(() => {
        const loadingTexts = ["Loading", "Loading.", "Loading..", "Loading..."];
        let index = 0;
        const interval = setInterval(() => {
            setLoadingText(loadingTexts[index]);
            index = (index + 1) % loadingTexts.length;
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const glitchCharacters = ['#', '$', '%', '&', '!', '@', '*'];
        const originalDate = "20/09/2024";
        let glitchInterval: NodeJS.Timeout;

        const applyGlitchEffect = () => {
            const dateArray = originalDate.split('');
            const randomIndex1 = Math.floor(Math.random() * dateArray.length);
            const randomIndex2 = Math.floor(Math.random() * dateArray.length);
            const randomChar1 = glitchCharacters[Math.floor(Math.random() * glitchCharacters.length)];
            const randomChar2 = glitchCharacters[Math.floor(Math.random() * glitchCharacters.length)];
            dateArray[randomIndex1] = randomChar1;
            dateArray[randomIndex2] = randomChar2;
            setGlitchDate(dateArray.join(''));
        };

        glitchInterval = setInterval(applyGlitchEffect, 1100);

        return () => clearInterval(glitchInterval);
    }, []);

    const [glitchDate, setGlitchDate] = useState("20/09/2024");

    return (
        <>
            <div className={`${currentStyles.mainContainer} home-content-view`}>
                <div className={currentStyles.innerContent}>
                    <h1>SNOWING PINE STORIES BETA IS LAUNCHING SOON</h1>
                    <h2>{loadingText}</h2>
                    <p>{glitchDate}</p>
                    
                    <div className={currentStyles.roadmapContainer}>
                        <h2>Changelog / Roadmap</h2>
                        <ul className={currentStyles.roadmapList}>
                            <li>
                                <span className={currentStyles.checkmark}>✓</span>
                                <span>Migrate to Vercel - (100% done)</span>
                            </li>
                            <li>
                                <span className={currentStyles.checkmark}>✓</span>
                                <span>Implement a New User Authentication Service (Supertokens) - (100% done)</span>
                            </li>
                            <li>
                                <span className={currentStyles.checkmark}>✓</span>
                                <span>Added Progressive Web App (PWA) Functionality - (100% done)</span>
                            </li>
                            <li>
                                <span className={currentStyles.checkmark}>✓</span>
                                <span>Migrated backend from Python Flask to Node.js - (100% done)</span>
                            </li>
                            <li>
                                <span className={currentStyles.checkmark}>✓</span>
                                <span>Migrated frontend to React Typescript - (100% done)</span>
                            </li>
                            <li>
                                <span className={currentStyles.checkmark}></span>
                                <span>New Feature Implementation</span>
                                <ul className={`${currentStyles.checkmarkList} ${currentStyles.roadmapSubList}`}>
                                    <li>
                                        <span className={currentStyles.subCheckmark}>✓</span>
                                        <span>Implemented a new, more stable text editor.</span>
                                    </li>
                                    <li>
                                        <span className={currentStyles.subCheckmark}>✓</span>
                                        <span>Added Update prompt when a new version of the site exists</span>
                                    </li>
                                    <li>
                                        <span className={currentStyles.subCheckmark}>✓</span>
                                        <span>Created Notifications Backend</span>
                                    </li>
                                    <li>
                                        <span className={currentStyles.subCheckmark}>✓</span>
                                        <span>Fiction Submissions Frontend / Backend</span>
                                    </li>
                                    <li>
                                        <span className={currentStyles.subCheckmark}></span>
                                        <span>Follow / Favorite Stories</span>
                                    </li>
                                    <li>
                                        <span className={currentStyles.subCheckmark}></span>
                                        <span>Reader Settings Menu (Light / Dark Mode, Font Size, etc)</span>
                                    </li>
                                    <li>
                                        <span className={currentStyles.subCheckmark}></span>
                                        <span>Feedback Page</span>
                                    </li>
                                    <li>
                                        <span className={currentStyles.subCheckmark}></span>
                                        <span>Bug Report Page</span>
                                    </li>
                                    <li>
                                        <span className={currentStyles.subCheckmark}></span>
                                        <span>Blogs Page for Devlogs</span>
                                    </li>
                                    <li>
                                        <span className={currentStyles.subCheckmark}></span>
                                        <span>User Onboarding / Username Choosing Proccess</span>
                                    </li>
                                    <li>
                                        <span className={currentStyles.subCheckmark}></span>
                                        <span>Feature Suggestion and Voting Page</span>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <span className={currentStyles.checkmark}></span>
                                <span>Re-Open Beta-Testing</span>
                            </li>
                            <li>
                                <span className={currentStyles.checkmark}></span>
                                <span>Iron Out the Inevitable Bugs</span>
                            </li>
                            <li>
                                <span className={currentStyles.checkmark}></span>
                                <span>More Features!</span>
                                <ul className={`${currentStyles.checkmarkList} ${currentStyles.roadmapSubList}`}>
                                    <li>
                                        <span className={currentStyles.subCheckmark}></span>
                                        <span>Comments on Chapters</span>
                                    </li>
                                    <li>
                                        <span className={currentStyles.subCheckmark}></span>
                                        <span>Messaging Functionality</span>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <span className={currentStyles.checkmark}></span>
                                <span>Official Launch</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={currentStyles.bottomLinksContainer}>
                    {links.map((link) => (
                        <div className={currentStyles.button} key={link.name} role={"button"} onClick={link.onClick}>
                            <img className={currentStyles.linkIcon} src={link.icon} alt={link.name} />
                            <div>
                                {link.name}
                            </div>
                        </div>
                    ))}
                    <button className={currentStyles.button} onClick={toggleTheme}>
                        <img src={isDarkMode ? LightModeWhiteFill : DarkModeBlack} alt="Toggle Theme" />
                        <span>{isDarkMode ? "Light Mode" : "Dark Mode"}</span>
                    </button>
                </div>
            </div>
        </>
    );
}