import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSessionContext } from "supertokens-auth-react/recipe/session";
import { PatreonWebhookImage } from "../assets/images";
import styles from "./AuthorDashboardContent.module.css";
import darkStyles from "./AuthorDarkDashboardContent.module.css";
import Cookies from "js-cookie";
import axios from "axios";
import { getApiDomain } from "../config";
import Loading from "../components/Loading";
import { json2csv } from 'json-2-csv';
import CSVReader from 'react-csv-reader';

export default function ContentView() {
    const navigate = useNavigate();
    const [isDarkMode, setIsDarkMode] = useState(() => {
        const savedTheme = Cookies.get("theme");
        return savedTheme === "dark";
    });
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [creatorToken, setCreatorToken] = useState<string | null>(null);
    const [inputToken, setInputToken] = useState("");
    const [patreonData, setPatreonData] = useState<any>(null);
    const [patreonTiers, setPatreonTiers] = useState<any>(null);
    const [patreonCreatorInfo, setPatreonCreatorInfo] = useState<any>(null);
    const [webhookSecret, setWebhookSecret] = useState('');
    const [webhookInserted, setWebhookInserted] = useState(false);
    const [userData, setUserData] = useState<any[]>([]);
    const [finishedFetching, setFinishedFetching] = useState(false);
    const [csvData, setCsvData] = useState<string | null>(null);
    const [userDataMapped, setUserDataMapped] = useState<any[]>([]);
    const [isFetchingPatrons, setIsFetchingPatrons] = useState(false);
    const [successfulDataSave, setSuccessfulDataSave] = useState(false);
    const [isSavingData, setIsSavingData] = useState(false);
    const [webhooks, setWebhooks] = useState<any[]>([]);
    const currentStyles = isDarkMode ? darkStyles : styles;

    async function fetchPatreon(token: any) {
        try {
            const response = await axios.get(`${getApiDomain()}/api/patreon/patreondata`, {
                params: {
                    token: token
                }
            });
            setPatreonData(response.data);
            console.log(response.data);
            const tiers = response.data.included.filter((item: any) => item.type === 'tier');
            setPatreonTiers(tiers);
            const creatorInfo = response.data.data[0].attributes;
            setPatreonCreatorInfo({
                oneLiner: creatorInfo.one_liner,
                pledgeUrl: creatorInfo.pledge_url,
                summary: creatorInfo.summary,
                url: creatorInfo.url,
                vanity: creatorInfo.vanity
            });
        } catch (error) {
            console.error('Error fetching Patreon data:', error);
        }
    }

    async function fetchWebhooks() {
        try {
            const response: any = await axios.get(`${getApiDomain()}/api/patreon/getallwebhooks`, {
                params: {
                    access_token: creatorToken
                }
            });
            setWebhooks(response.data);
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching Patreon data:', error);
        }
    }

    async function restartWebhook(webhookId: string) {
        try {
            const response: any = await axios.post(`${getApiDomain()}/api/patreon/restartwebhook`, {
                webhook_id: webhookId
            }, {
                params: {
                    access_token: creatorToken
                }
            });
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching Patreon data:', error);
        }
    }

    async function ensureTableExists(tableName: string) {
        try {
            const response: any = await axios.post(`${getApiDomain()}/api/patreon/ensuretableexists`, {
                table_name: tableName
            });
            return response;
        } catch (error) {
            console.error('Error fetching Patreon data:', error);
        }
    }

    async function getPatrons() {
        setIsFetchingPatrons(true);
        let cursor = null;
        try {
            let allPatrons: any[] = [];
            while (true) {
                const response: any = await axios.post(`${getApiDomain()}/api/patreon/getallmembers`, {
                    access_token: creatorToken,
                    campaign_id: patreonData.data[0].id,
                    page_size: 100,
                    cursor: cursor
                });
                if (response.status === 200) {
                    console.log('Patrons fetched successfully');
                }
                allPatrons = allPatrons.concat(response.data.data.data);
                cursor = response.data.nextCursor;
                if (!cursor) break;
            }
            setUserData(allPatrons);
            setFinishedFetching(true);

            // Generate CSV
            const fields = [
                'id', 'email', 'full_name', 'campaign_id', 'patron_status', 
                'currently_entitled_amount_cents', 'lifetime_support_cents', 
                'last_charge_date', 'last_charge_status', 'next_charge_date', 
                'pledge_relationship_start', 'pledge_cadence', 'will_pay_amount_cents', 
                'tier_id', 'tier_title'
            ];
            const userDataMappedVar = allPatrons.map((user: any) => {
                // Find the tier with the highest amount paid
                const entitledTiers = user.relationships.currently_entitled_tiers?.data || [];
                let highestTier = entitledTiers[0] || { id: null };
                entitledTiers.forEach((tier: any) => {
                    const tierInfo = patreonTiers.find((t: any) => t.id === tier.id);
                    const highestTierInfo = patreonTiers.find((t: any) => t.id === highestTier.id);
                    if (tierInfo && highestTierInfo && tierInfo.attributes.amount_cents > highestTierInfo.attributes.amount_cents) {
                        highestTier = tier;
                    }
                });

                // Find the title of the highest tier
                const highestTierInfo = patreonTiers.find((t: any) => t.id === highestTier.id);
                const tierTitle = highestTierInfo ? highestTierInfo.attributes.title : null;

                return {
                    id: user.id,
                    email: user.attributes.email.toLowerCase(),
                    full_name: user.attributes.full_name,
                    campaign_id: patreonData.data[0].id,
                    patron_status: user.attributes.patron_status,
                    currently_entitled_amount_cents: user.attributes.currently_entitled_amount_cents,
                    lifetime_support_cents: user.attributes.lifetime_support_cents,
                    last_charge_date: user.attributes.last_charge_date ? new Date(user.attributes.last_charge_date).toLocaleDateString() : null,
                    last_charge_status: user.attributes.last_charge_status,
                    next_charge_date: user.attributes.next_charge_date ? new Date(user.attributes.next_charge_date).toLocaleDateString() : null,
                    pledge_relationship_start: user.attributes.pledge_relationship_start ? new Date(user.attributes.pledge_relationship_start).toLocaleDateString() : null,
                    pledge_cadence: user.attributes.pledge_cadence,
                    will_pay_amount_cents: user.attributes.will_pay_amount_cents,
                    tier_id: highestTier.id,
                    tier_title: tierTitle
                };
            });
            
            setUserDataMapped(userDataMappedVar);
            console.log(userDataMappedVar);
            const csv = await json2csv(userDataMappedVar, { keys: fields });
            setCsvData(csv);
        } catch (error) {
            console.error('Error fetching patrons:', error);
            alert('An error occurred while fetching the patrons. Please try again.');
        } finally {
            setIsFetchingPatrons(false);
        }
    }

    const handleTokenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const token = e.target.value;
        setInputToken(token);
        setCreatorToken(token);
    };

    const handleWebhookChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setWebhookSecret(value);
        if (value.length > 60) {
            setWebhookInserted(true);
        } else {
            setWebhookInserted(false);
        }
    };

    const renderCsvTable = (csvData: string) => {
        const rows = csvData.split('\n').map(row => row.split(','));
        const headers = rows[0];
        const data = rows.slice(1);

        return (
            <table className={currentStyles.csvTable}>
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <th key={index}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {row.map((cell, cellIndex) => (
                                <td key={cellIndex}>{cell}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    async function saveUserList() {
        try {
            const table_name = `Patreon_TEST_${patreonCreatorInfo.vanity}`;
            await ensureTableExists(table_name);
            let chunkSize = 50;
            let successCount = 0;
            for (let i = 0; i < userDataMapped.length; i += chunkSize) {
                const chunk = userDataMapped.slice(i, i + chunkSize);
                const response = await axios.post(`${getApiDomain()}/api/patreon/savemembers`, {                
                    user_data_mapped: chunk, table_name: table_name});

                if (response.status === 200) {
                    console.log(`User data chunk ${i / chunkSize + 1} saved successfully!`);
                    successCount++;
                } else {
                    alert(`Failed to save data chunk ${i / chunkSize + 1}. Please try again.`);
                    break;
                }
            }
            if (successCount === Math.ceil(userDataMapped.length / chunkSize)) {
                setSuccessfulDataSave(true);
            }
        } catch (error) {
            console.error('Error saving data:', error);
            alert('An error occurred while saving the data. Please try again.');
        } finally {
            setIsSavingData(false);
        }
    }

    async function saveData() {
        setIsSavingData(true);
        try {
            const response = await axios.post(`${getApiDomain()}/api/patreon/save`, {                
                campaign_id: patreonData.data[0].id,
                vanity: patreonCreatorInfo.vanity,
                url: patreonCreatorInfo.url,
                tiers_data: patreonTiers,
                raw_data: patreonData,
                raw_creator_info: patreonCreatorInfo,
                webhook_secret: webhookSecret});

            if (response.status === 200) {
                console.log('Data saved successfully! Procceeding to next step.');
                saveUserList();
            } else {
                alert('Failed to save data. Please try again.');
            }
        } catch (error) {
            console.error('Error saving data:', error);
            alert('An error occurred while saving the data. Please try again.');
        }
    }

    return (
        <>
            <div className={currentStyles.mainContainer}>
                <div className={currentStyles.innerContent}>
                    <div className={currentStyles.innerContainer}>
                        <h1 className={currentStyles.title}>Patreon Configuration</h1>
                        <p>In this page you can setup and manage your Patreon integration with Snowingpine.</p>
                        {successfulDataSave && (
                            <div className={currentStyles.infoContainer}>
                                <h2>Patreon successfully linked.</h2>
                                <button className={currentStyles.fetchButton}>Continue</button>
                            </div>
                        )}
                        {successfulDataSave === false && (
                            <>
                                <div className={currentStyles.infoContainer}>
                                    <p>First, go to <a href="https://www.patreon.com/portal/registration/register-clients" target="_blank" rel="noopener noreferrer" style={{ color: '#1e90ff' }}>https://www.patreon.com/portal/registration/register-clients</a> and click on Create Client.</p>
                                    <p>Fill in an App Name and Description. For the required redirect URI, just use <strong>https://www.google.com</strong> because we do not need it. Make sure the API version is 2 and click Create.</p>
                                    <p>Afterwards, click on the arrow to expand the client info, and get the Creator's Access Token. Insert it below.</p>
                                </div>
                                <span style={{ marginLeft: '5px' }}>Creator Access Token</span>
                                <div className={currentStyles.inputContainer}>
                                        <input
                                            type="text"
                                            name="accessToken"
                                            value={inputToken}
                                            onChange={handleTokenChange}
                                            placeholder="Enter Creator Token"
                                            className={currentStyles.inputField}
                                        />
                                    <button onClick={() => fetchPatreon(creatorToken)} className={currentStyles.fetchButton}>Fetch Patreon Data</button>
                                </div>

                                {patreonData && (
                                    <>
                                        <div className={currentStyles.infoContainer}>
                                            <h2>Great! Now, let's create a webhook.</h2>
                                            <p>Please navigate to <a href="https://www.patreon.com/portal/registration/register-webhooks" target="_blank" rel="noopener noreferrer">Patreon Webhook Registration</a> and create a webhook using the URL: <strong>https://api.snowingpine.com/webhook/patreon/snowingpine</strong></p>
                                            <p>Make sure to enable the following, and only the following:</p>
                                            <ul>
                                                <li>Create Member</li>
                                                <li>Update Member</li>
                                                <li>Delete Member</li>
                                                <li>Create Member Pledge</li>
                                                <li>Update Member Pledge</li>
                                                <li>Delete Member Pledge</li>
                                            </ul>
                                            <details>
                                                <summary  style={{ cursor: 'pointer' }} >
                                                    <strong>Click here to see an explanation.</strong>
                                                </summary>
                                                <br></br>
                                                <img src={PatreonWebhookImage} alt="Show/Hide" style={{ maxWidth: '500px' }}/>
                                            </details>
                                            <p>Afterwards, simply copy the webhook secret and add it here. Without it, the API will not be able to validate the data that your Patreon will send, and reject it.</p>
                                        </div>
                                        <span style={{ marginLeft: '5px' }}>Webhook Secret</span>
                                        <div className={currentStyles.inputContainer}>
                                            <input
                                                type="text"
                                                name="webhookSecret"
                                                value={webhookSecret}
                                                onChange={handleWebhookChange}
                                                placeholder="Enter Webhook Secret"
                                                className={currentStyles.inputField}
                                            />
                                        </div>
                                    </>
                                )}
                                
                                {webhookInserted && (
                                    <div className={currentStyles.infoContainer}>
                                        <h2>Almost Done! Let's import your members.</h2>
                                        {/* <button onClick={fetchWebhooks} className={currentStyles.fetchButton}>
                                            Fetch Webhooks
                                        </button>
                                        <button onClick={() => restartWebhook('753763')} className={currentStyles.fetchButton}>
                                            Restart Webhook
                                        </button>
                                        <div className={currentStyles.webhookDataContainer}>
                                            <h3>Webhooks Data:</h3>
                                            <pre>{JSON.stringify(webhooks, null, 2)}</pre>
                                        </div> */}
                                        <button onClick={getPatrons} className={currentStyles.fetchButton} disabled={isFetchingPatrons}>
                                            {isFetchingPatrons ? 'Fetching...' : 'Get Patrons'}
                                        </button>
                                        {csvData && (
                                            <>
                                            <p>Here is the data that will be saved to the database.</p>
                                            <div className={currentStyles.csvContainer}>
                                                {renderCsvTable(csvData)}
                                            </div>
                                            </>
                                        )}
                                    </div>
                                )}

                                {userDataMapped.length > 0 && (
                                    <div className={currentStyles.infoContainer}>
                                        <h2>Final Step, save the data to the database.</h2>
                                        <button onClick={saveData} className={currentStyles.fetchButton} disabled={isSavingData || successfulDataSave}>
                                            {isSavingData ? 'Saving...' : 'Save Data'}
                                        </button>
                                    </div>
                                )}

                                {patreonData &&(
                                    <>
                                        <br />
                                        <br />
                                        <h2>Fetched Patreon Info (For reference)</h2>
                                    </>)}
                                {patreonCreatorInfo && (
                                    <div className={currentStyles.creatorInfo}>
                                        <h2>{patreonCreatorInfo.vanity}</h2>
                                        <div dangerouslySetInnerHTML={{ __html: patreonCreatorInfo.summary }} />
                                        <a href={patreonCreatorInfo.url} target="_blank" rel="noopener noreferrer">Visit Patreon Page</a>
                                    </div>
                                )}
                                {patreonTiers && (
                                    <div className={currentStyles.cardsContainer}>
                                        {patreonTiers.map((tier: any) => (
                                            tier.attributes.title.toLowerCase() !== 'free' && (
                                                <div key={tier.id} className={currentStyles.card}>
                                                    <h2>{tier.attributes.title}</h2>
                                                    <p>Amount: ${tier.attributes.amount_cents / 100}</p>
                                                    <div 
                                                        dangerouslySetInnerHTML={{ 
                                                            __html: tier.attributes.description.replace(
                                                                /<a /g, '<a style="cursor: pointer; text-decoration: none; color: inherit;" '
                                                            ) 
                                                        }} 
                                                    />
                                                </div>
                                            )
                                        ))}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}