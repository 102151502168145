import React, { useRef, useState, useEffect } from 'react';
import styles from './TapMenu.module.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

interface TapMenuProps {
  setDrawerOpen: (open: boolean) => void;
  changeTheme: (theme: string) => void;
  setFontSize: (size: number) => void;
  fontSize: number;
  fictionID: string;
}

const TapMenu: React.FC<TapMenuProps> = ({
  setDrawerOpen,
  changeTheme,
  setFontSize,
  fontSize,
  fictionID,
}) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const savedFontSize = Cookies.get('fontSize');
    if (savedFontSize) {
      setFontSize(parseInt(savedFontSize, 10));
    }
  }, []);

  const fictionNavigation = (ID: string) => {
    if (ID === 'null') {
      navigate('/');
    } else {
      navigate(`/fiction/${ID}`);
    }
  };

  const handleFontSizeChange = (newSize: number) => {
    setFontSize(newSize);
    Cookies.set('fontSize', newSize.toString(), { expires: 365 });
  };

  const handleSliderEventInternal = (e: React.MouseEvent | React.TouchEvent) => {
    const slider = sliderRef.current;
    if (!slider) return;
    const rect = slider.getBoundingClientRect();
    let offsetX = 0; // Initialize with a default value

    if (e.type === 'mousedown' || e.type === 'mousemove') {
      offsetX = (e as React.MouseEvent).clientX - rect.left;
    } else if (e.type === 'touchstart' || e.type === 'touchmove') {
      offsetX = (e as React.TouchEvent).touches[0].clientX - rect.left;
    }

    const newFontSize = Math.round((offsetX / rect.width) * 12 + 12);
    handleFontSizeChange(newFontSize);
  };

  useEffect(() => {
    const slider = sliderRef.current;

    if (!slider) return;

    const handleMouseMove = (e: MouseEvent) => {
      if (e.buttons === 1) handleSliderEventInternal(e as unknown as React.MouseEvent);
    };

    const handleTouchMove = (e: TouchEvent) => handleSliderEventInternal(e as unknown as React.TouchEvent);

    slider.addEventListener('mousemove', handleMouseMove);
    slider.addEventListener('touchmove', handleTouchMove);

    return () => {
      if (!slider) return;
      slider.removeEventListener('mousemove', handleMouseMove);
      slider.removeEventListener('touchmove', handleTouchMove);
    };
  }, [sliderRef]);

  return (
    <div className={styles.drawerOverlay} onClick={() => setDrawerOpen(false)}>
      <div
        className={styles.drawerContentBottom}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.themeCircles}>
          <div
            className={`${styles.themeCircle} ${styles.lightTheme}`}
            onClick={() => changeTheme('light')}
          />
          <div
            className={`${styles.themeCircle} ${styles.paleBeigeTheme}`}
            onClick={() => changeTheme('pale-beige')}
          />
          <div
            className={`${styles.themeCircle} ${styles.parchmentTheme}`}
            onClick={() => changeTheme('parchment')}
          />
          <div
            className={`${styles.themeCircle} ${styles.darkTheme}`}
            onClick={() => changeTheme('dark')}
          />
        </div>
        <div
          ref={sliderRef}
          className={styles.customSlider}
          onMouseDown={handleSliderEventInternal}
          onTouchStart={handleSliderEventInternal}
          onMouseMove={(e) => e.buttons === 1 && handleSliderEventInternal(e)}
          onTouchMove={handleSliderEventInternal}
        >
          <div
            className={styles.customSliderFill}
            style={{ width: `${((fontSize - 12) / 12) * 100}%` }}
          />
          <span className={styles.sliderLabel}>Font Size</span>
        </div>
        <div className={styles.chapterNav}>
          <button
            className={styles.navButton}
            onClick={(e) => {
              e.stopPropagation();
              fictionNavigation(fictionID);
            }}
          >
            Fiction Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default TapMenu;
