import { SW_INIT, SW_UPDATE } from './types';
import { RootState } from './store';

type Action =
  | { type: typeof SW_INIT }
  | { type: typeof SW_UPDATE; payload: ServiceWorkerRegistration };

const initialState: RootState = {
  serviceWorkerInitialized: false,
  serviceWorkerUpdated: false,
  serviceWorkerRegistration: null,
};

function rootReducer(state = initialState, action: Action): RootState {
  switch (action.type) {
    case SW_INIT:
      return {
        ...state,
        serviceWorkerInitialized: !state.serviceWorkerInitialized,
      };
    case SW_UPDATE:
      return {
        ...state,
        serviceWorkerUpdated: !state.serviceWorkerUpdated,
        serviceWorkerRegistration: action.payload,
      };
    default:
      return state;
  }
}

export default rootReducer;
